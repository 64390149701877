import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { bigScreen } from '../../../jsStyles/viewport';

export const DocumentWrapper = styled.div`
  height: 100%;
`;

export const DocumentContent = styled.div`
    margin: 0 auto;
    max-width: ${bigScreen};
`;

export const DocumentTop = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayDark};
    padding: 2rem;
    position: relative;
`;

export const Document = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    gap: 2rem;
`;

export const Container = styled.div`
    position: relative;
`;

export const DocumentImg = styled.div`
    position: sticky;
    align-self: flex-start;
    top: 1rem;
`;

export const AnchorLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    font-family: ${({ theme }) => theme.fonts.normal};

    &:hover{
        color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

export const Share = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    margin: 2rem;
`;

export const ClipboardButton = styled.button`
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
`;

export const Table = styled.table`
    border-collapse: collapse;
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.colors.text};
    width: 100%;
`;

export const TBody = styled.tbody``;

export const Tr = styled.tr`
    border: 1px solid ${({ theme }) => theme.colors.gray};
    margin: 0;

    &:nth-child(even){
        background-color: ${({ theme }) => theme.colors.light};
    }
`;

export const Td = styled.td`
    padding: 0.5rem 1rem;
    word-wrap: break-word;
    white-space: normal;
    line-height: 140%;
    overflow-wrap: break-word;

    &:first-of-type{
        vertical-align: top;
        text-align: end;
        border-right: 1px solid ${({ theme }) => theme.colors.grayDark};
        font-family: ${({ theme }) => theme.fonts.normal};
    }

    &:last-of-type{
        font-family: ${({ theme }) => theme.fonts.light};
    }

    & > a {
        display: flex;
        & > img {
            max-width: 5rem;
        } 
    }
    

`;

export const RelatedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
`;

export const Title = styled.h3`
    font-family: ${({ theme }) => theme.fonts.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    margin-top: 0;
`;
