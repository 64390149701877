import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import ROUTES from '../constants/routes';
import {
  logout,
  userAssigned,
  fetchUserData,
  removeUser,
} from '../api/auth';

const LoginContext = createContext({});

const LoginContextProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(userAssigned());

  const minsToMilis = (minutes) => minutes * 60 * 1000;
  const terminationLeeway = process.env.REACT_APP_SESSION_REFRESH_INTERVAL - 1;
  const timeout = process.env.REACT_APP_SESSION_TIME - terminationLeeway;

  const {
    start: startIdleTimer,
    pause: pauseIdleTimer,
    reset: resetIdleTimer,
    getTotalElapsedTime,
  } = useIdleTimer({
    onIdle: () => {
      logout()
        .then(() => {
          setIsLogged(false);
          window.location.replace(ROUTES.home);
          window.refresh();
        })
        .catch(() => {
          removeUser();
          window.location.replace(ROUTES.home);
          window.refresh();
        });
    },
    onAction: () => {
      if (getTotalElapsedTime() > minsToMilis(process.env.REACT_APP_SESSION_REFRESH_INTERVAL)) {
        fetchUserData()
          .then(resetIdleTimer());
      }
    },
    timeout: minsToMilis(timeout),
    events: ['mousedown', 'mousewheel', 'keydown'],
    eventsThrottle: 200,
    debounce: 1000,
    startOnMount: false,
    startManually: true,
    element: document,
    crossTab: true,
  });

  useEffect(() => {
    if (userAssigned()) {
      setIsLogged(true);
      startIdleTimer();
    } else {
      setIsLogged(false);
      resetIdleTimer();
      pauseIdleTimer();
    }
  });

  return (
    <LoginContext.Provider value={{ isLogged, setIsLogged }}>
      {children}
    </LoginContext.Provider>
  );
};

LoginContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LoginContext, LoginContextProvider };
