import styled from 'styled-components/macro';

export const FacetsWrapper = styled.div`
    font-family: ${({ theme }) => theme.fonts.normal};
    max-width: 16rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const FilterWrapper = styled.div`
    line-height: 1.5rem;
    border: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const Title = styled.h4`
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    border-bottom: ${({ isOpen, theme }) => (isOpen ? `1px solid ${theme.colors.gray}` : 'none')};
    padding: 0.1rem .5rem;
    background-color: ${({ theme }) => theme.colors.gray};
    position: relative;
    cursor: pointer;

    &::before,
    &::after{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0,-50%);
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        margin-right: 1rem;
    }

    &::before{
        border-bottom: 8px solid ${({ theme }) => theme.colors.grayDark};
        top: 32%;
    }
    
    &::after{
        border-top: 8px solid ${({ theme }) => theme.colors.grayDark};
        top: 67%;
    }

    &:hover, &:focus{
        background-color: ${({ theme }) => theme.colors.grayLight};
    }

    &:hover, &:focus {
        color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

export const FilterText = styled.span`
    margin-right: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .9rem;
`;

export const FilterCounter = styled.span`
    font-family: ${({ theme }) => theme.fonts.light};
`;

export const FilterOptions = styled.div`
    color: ${({ theme }) => theme.colors.text};
    justify-content: space-between;
    align-items: center;
    padding: .3rem 1rem;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.light};
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray}`};

    &:hover, &:focus{
        background-color: ${({ theme }) => theme.colors.grayLight};
    }

    &:hover ${FilterText}, &:focus ${FilterText}{
        color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

export const MoreWrapper = styled(FilterOptions)`
    justify-content: center;
    align-items: center;
`;
