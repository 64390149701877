import styled from 'styled-components/macro';

const StyledButton = styled.button`
    color: ${({ theme }) => theme.colors.lighter};
    background-color: ${({ theme }) => theme.colors.primary};
    border: none;
    display: flex;
    gap: .5rem;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem 1rem;
    font-family: ${({ theme }) => theme.fonts.normal};
    margin: 0 auto;

    &:hover{
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

export default StyledButton;
