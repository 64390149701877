import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import { getLatestSearchList, setLatestSearchList } from '../localstorage/search';

const LatestSearchContext = createContext({});

const LatestSearchContextProvider = ({ children }) => {
  const [latestSearchs, setLatestSearch] = useState(getLatestSearchList());

  const handleLatestSearchs = (search) => {
    const id = uuid();
    const date = format(new Date(), 'yyyy-MM-dd HH:mm');
    setLatestSearchList({ date, id, search });
    setLatestSearch(getLatestSearchList());
  };

  return (
    <LatestSearchContext.Provider value={{
      latestSearchs, handleLatestSearchs,
    }}
    >
      {children}
    </LatestSearchContext.Provider>
  );
};

LatestSearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LatestSearchContext, LatestSearchContextProvider };
