import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiFillEye } from 'react-icons/ai';
import { CoverContainer } from '../../containers';
import { DocumentImg } from './styles/document';
import ROUTES from '../../constants/routes';
import { Button } from '../../shared';
import { SearchParamsContext } from '../../contexts/SearchParams';

const DocumentImage = ({ id }) => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const { searchParams } = useContext(SearchParamsContext);

  const redirect = () => history.push(`${ROUTES.viewer}/${params.name}?highlight=${searchParams.ocr_text}`);

  return (
    <DocumentImg>
      <CoverContainer id={id} />
      <Button onClick={redirect}>
        {t('document.info.view')}
        <AiFillEye size={18} />
      </Button>
    </DocumentImg>
  );
};
DocumentImage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DocumentImage;
