import React from 'react';
import PropTypes from 'prop-types';
import defaultImage from '../../assets/images/img/default_img.jpg';
import Img from './styles/cover';

const Cover = ({ cover }) => {
  const {
    vol_path: volPath, file_path_type: filePathType, file_path: filePath, file_name: fileName,
  } = cover;

  if (filePathType === '') {
    return <Img url={defaultImage} data-testid="default-cover" />;
  }

  if (filePathType === 'url') {
    return <Img url={filePath} data-testid="external-cover" />;
  }

  return (
    <Img
      url={`${process.env.REACT_APP_IMAGE_SERVER}/${encodeURIComponent(`${volPath}/${filePath}${fileName}`)}/full/288,/0/default.jpg`}
      data-testid="cover"
    />
  );
};

Cover.propTypes = {
  cover: PropTypes.shape({
    file_name: PropTypes.string,
    file_path: PropTypes.string,
    vol_path: PropTypes.string,
    file_path_type: PropTypes.string,
  }).isRequired,
};

export default Cover;
