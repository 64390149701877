import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Skeleton } from '@material-ui/lab';
import { FacetsWrapper } from './styles/facets';
import Filter from './Filter';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { MULTI_PARAMS, FACET_ORDER } from '../../constants/searchParams';

const Facets = ({ data, isLoading }) => {
  const { facet_fields: facetFields } = data;
  const { REDUCER_CASES } = useContext(SearchParamsContext);

  const handleType = (category) => (MULTI_PARAMS.some((c) => category === c)
    ? REDUCER_CASES.addMultiField
    : REDUCER_CASES.addSingleField);

  const sortFacets = (facets) => Object.entries(facets)
    .sort((a, b) => FACET_ORDER.indexOf(a[0]) - FACET_ORDER.indexOf(b[0]));

  return (
    <FacetsWrapper>
      {isEmpty(facetFields)
        ? Array.from(Array(10).keys()).map((i) => (
          <Skeleton height="14.3rem" width="16rem" variant="rect" key={i} />
        ))
        : sortFacets(facetFields).map((f) => {
          const [category, facet] = f;
          return (
            <Filter
              category={category}
              facet={facet}
              isLoading={isLoading}
              type={handleType(category)}
              key={category}
            />
          );
        })}
    </FacetsWrapper>
  );
};

Facets.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.array),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Facets;
