import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import Loader from 'react-loader-spinner';
import { fetchConf } from '../api/db';
import { theme } from '../jsStyles/colorPalette';

const ConfContext = createContext({});

function sanitize(prev, next) {
  return { ...prev, [next.variable]: next };
}

const ConfContextProvider = ({ children }) => {
  const {
    data, error, isLoading, isError,
  } = useQuery(['conf'], () => fetchConf(), { refetchOnWindowFocus: false });

  const [env, setEnv] = useState({});

  useEffect(() => {
    if (data && data.length > 0) {
      const sanitizedConf = data.reduce(sanitize, {});
      setEnv(sanitizedConf);
    }
  }, [data]);

  if (isLoading) {
    return <Loader type="ThreeDots" height={30} color={theme.colors.primaryLight} className="loader" />;
  }

  if (isError) {
    return (
      <span>
        Error:
        {error}
      </span>
    );
  }

  return (
    <ConfContext.Provider value={{ env }}>
      {children}
    </ConfContext.Provider>
  );
};

ConfContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ConfContext, ConfContextProvider };
