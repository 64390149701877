import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import Loader from 'react-loader-spinner';
import { fetchRelatedDocuments } from '../../api/solr';
import { theme } from '../../jsStyles/colorPalette';
import RelatedDocuments from '../../components/document/RelatedDocuments';

const RelatedDocumentsContainer = ({ params }) => {
  const {
    data, isLoading, isError,
  } = useQuery(['relatedDocuments', params], () => fetchRelatedDocuments(params),
    {
      refetchOnWindowFocus: false,
    });

  if (isLoading) {
    return <Loader type="ThreeDots" color={theme.colors.primaryLight} height={30} className="loader" />;
  }

  if (isError) {
    return (
      <span>
        Error
      </span>
    );
  }

  return (
    <RelatedDocuments related={data.documents} />
  );
};

RelatedDocumentsContainer.propTypes = {
  params: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default RelatedDocumentsContainer;
