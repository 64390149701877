import React from 'react';
import PropTypes from 'prop-types';
import { Image } from './styles/snippetCover';

const SnippetCover = ({ cover, coordinates }) => {
  const {
    vol_path: volPath, file_path_type: filePathType, file_path: filePath, file_name: fileName,
  } = cover;

  const {
    rx, ry, rw, rh,
  } = coordinates;

  if (filePathType !== 'url') {
    const url = encodeURIComponent(`${volPath}/${filePath}${fileName}`);
    return (
      <Image src={`${process.env.REACT_APP_IMAGE_SERVER}/${url}/${rx},${ry},${rw},${rh}/,256/0/default.jpg`} />
    );
  }

  const url = encodeURIComponent(filePath);
  return (
    <Image src={`${process.env.REACT_APP_IMAGE_SERVER}/${url}/${rx},${ry},${rw},${rh}/,256/0/default.jpg`} />
  );
};

SnippetCover.propTypes = {
  cover: PropTypes.shape({
    vol_path: PropTypes.string,
    file_path_type: PropTypes.string,
    file_path: PropTypes.string,
    file_name: PropTypes.string,
  }).isRequired,
  coordinates: PropTypes.shape({
    rx: PropTypes.number,
    ry: PropTypes.number,
    rw: PropTypes.number,
    rh: PropTypes.number,
  }).isRequired,
};

export default SnippetCover;
