import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AnchorLink, Table, TBody, Tr, Td,
} from './styles/document';
import { RightsContainer } from '../../containers';

const createMarkup = (markup) => ({ __html: markup });

const InfoTable = ({ info }) => {
  const { t } = useTranslation();

  const {
    object_creator: objectCreator,
    object_data: objectData,
    object_data_notes: objectDataNotes,
    object_desc: objectDesc,
    object_disseminator: objectDisseminator,
    object_identifier: objectIdentifier,
    object_institution_desc: objectInstitutionDesc,
    object_institution_id: objectInstitutionId,
    object_language: objectLanguage,
    object_type: objectType,
    object_title: objectTitle,
    object_reference: objectReference,
    object_format: objectFormat,
    fond_name: fondName,
    object_fromdate: objectFromdate,
    object_todate: objectTodate,
    right_id: rightId,
    object_alt_link: objectAltLink,
  } = info;

  return (
    <Table>
      <TBody>
        <Tr>
          <Td>{t('document.info.title')}</Td>
          <Td>{objectTitle || '--'}</Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.identifier')}</Td>
          <Td>{objectIdentifier || '--'}</Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.reference')}</Td>
          <Td>{objectReference || '--'}</Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.fond')}</Td>
          <Td>{fondName || '--'}</Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.creator')}</Td>
          <Td>{objectCreator || '--'}</Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.date')}</Td>
          <Td>
            {
            (objectData
                && objectData)
            || objectDataNotes
            || `${objectFromdate || ''}-${objectTodate || '--'}`
          }
          </Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.lang')}</Td>
          <Td>{objectLanguage || '--'}</Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.type')}</Td>
          <Td>{objectType || '--'}</Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.format')}</Td>
          <Td>{objectFormat || '--'}</Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.desc')}</Td>
          <Td dangerouslySetInnerHTML={createMarkup(objectDesc)} />
        </Tr>
        <Tr>
          <Td>{t('document.info.institution')}</Td>
          <Td>
            {objectInstitutionId || '--'}
            <br />
            {objectInstitutionDesc || '--'}
          </Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.rights')}</Td>
          <Td><RightsContainer rightId={rightId} /></Td>
        </Tr>
        <Tr>
          <Td>{t('document.info.others')}</Td>
          <Td>
            {objectDisseminator
              ? (
                <AnchorLink to={{ pathname: objectAltLink }} target="_blank">
                  {objectDisseminator}
                </AnchorLink>
              )
              : '--'}

          </Td>
        </Tr>
      </TBody>
    </Table>
  );
};

InfoTable.propTypes = {
  info: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default InfoTable;
