import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import {
  Home, Search, Documents, Document, Viewer,
} from '../pages';
import { Navbar, Footer } from '../components';
import { IsOpenContextProvider } from '../contexts/IsOpenContext';
import ROUTES from '../constants/routes';
import CasGuard from './CasGuard';

const Router = () => (
  <BrowserRouter>
    <CasGuard>
      <IsOpenContextProvider>
        <Navbar />
      </IsOpenContextProvider>
      <Switch>
        <Route strict path={ROUTES.search} component={Search} />
        <Route strict path={ROUTES.documents} component={Documents} />
        <Route strict path={`${ROUTES.document}/:name`} component={Document} />
        <Route strict path={`${ROUTES.viewer}/:name`} component={Viewer} />
        <Route strict path={ROUTES.home} component={Home} />
      </Switch>
      <Footer />
    </CasGuard>
  </BrowserRouter>
);

export default Router;
