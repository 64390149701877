import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { fetchDocument } from '../../api/db';
import { DocumentCompound } from '../../components';
import { theme } from '../../jsStyles/colorPalette';
import ROUTES from '../../constants/routes';
import { LoginContext } from '../../contexts/LoginContext';
import { removeUser } from '../../api/auth';
import FetchError from '../../components/FetchError';

const DocumentContainer = () => {
  const { name } = useParams();
  const { setIsLogged } = useContext(LoginContext);
  const { t } = useTranslation();

  const {
    data: document, isLoading: isLoadingDoc, isError: isErrorDoc, error,
  } = useQuery(['document', name], () => fetchDocument(name), { refetchOnWindowFocus: false });

  if (isLoadingDoc) {
    return <Loader type="ThreeDots" color={theme.colors.primaryLight} height={30} className="loader" />;
  }

  if (isErrorDoc) {
    const status = error.response?.status;
    if (status >= 400 && status < 500) {
      removeUser();
      setIsLogged(false);
      window.location.replace(ROUTES.home);
      return <FetchError message={t('errors.unauthorized')} />;
    }

    return <FetchError message={t('errors.generic')} />;
  }

  return (
    <DocumentCompound document={document} />
  );
};

export default DocumentContainer;
