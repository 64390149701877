import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import Searchbar from './Searchbar';
import {
  Background, HeroContent, Title, Subtitle, Attribution,
} from './styles/hero';
import { ConfContext } from '../../contexts/ConfContext';

import { StatsContainer } from '../../containers';
import { Button } from '../../shared';
import ROUTES from '../../constants/routes';

const Hero = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { env } = useContext(ConfContext);

  const { home_image: background, home_image_info: homeImageInfo } = env;

  const redirect = () => history.push(ROUTES.search);

  return (
    <Background background={background.var_value}>
      <HeroContent>
        <Title>{t('hero.title')}</Title>
        <Subtitle>{t('hero.subtitle')}</Subtitle>
        <Searchbar />
        <StatsContainer />
        <Button onClick={redirect}>
          {t('hero.button')}
          <AiOutlineSearch size={18} />
        </Button>
      </HeroContent>
      <Attribution dangerouslySetInnerHTML={{ __html: homeImageInfo.var_value }} />
    </Background>
  );
};

export default Hero;
