import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { bigScreen } from '../../../jsStyles/viewport';

export const HeaderWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
`;

export const Container = styled.header`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    top: 0;
    width: 65%;
    max-width: ${bigScreen};
    margin: 0 auto;
`;

export const LineSeparator = styled.div`
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.lighter};
    margin: 0;
    width: 100%;
`;

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    margin: 0 auto;
    width: 100%;
    min-height: 49px;
`;

export const TextLogo = styled(Link)`
    color: ${({ theme }) => theme.colors.lighter};
    height: 48px;
    font-size: 48px;
    text-decoration: none;
    box-shadow: inset 1px 0 0 0 ${({ theme }) => theme.colors.lighter};
    margin: 0 10px;
    padding: 0 10px;
`;

export const HeaderAnchor = styled.a`
  display: flex;
`;

export const Logo = styled.img`
    object-fit: contain;
    height: 3rem;
    width: auto;
`;

export const Links = styled.nav`
    display: flex;
    justify-content: center;
    margin-right: auto;
`;

export const ButtonLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    color: ${({ theme }) => theme.colors.light};
    font-family: ${({ theme }) => theme.fonts.normal};
    transition: background-color .2s;
    text-decoration: none;
    height: 48px;

    &:last-child {
        flex: 1;
    }

    &:hover{
        cursor: pointer;
        background-color: rgba(0,0,0,.2);
    }
`;

export const Button = styled.button`
    padding: 0 1rem;
    color: ${({ theme }) => theme.colors.light};
    font-family: ${({ theme }) => theme.fonts.normal};
    background-color: transparent;
    border: none;
    height: 48px;

    &:hover{
        cursor: pointer;
        background-color: rgba(0,0,0,.2);
    }
`;
