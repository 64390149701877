import React from 'react';
import PropTypes from 'prop-types';
import GridItem from './GridItem';
import { GridContainer } from './styles/documents';
import GridContentSkeletonItem from './GridContentSkeletonItem';

const GridContent = ({ data, isLoading }) => {
  if (isLoading) {
    return (
      <GridContainer border>
        {Array.from(Array(25).keys()).map((i) => (<GridContentSkeletonItem key={i} />))}
      </GridContainer>
    );
  }

  return (
    <GridContainer border>
      {data.map(({ doc, results }) => (
        <GridItem key={doc.object_uuid} doc={doc} results={results} />
      ))}
    </GridContainer>
  );
};

GridContent.defaultProps = {
  isLoading: true,
};

GridContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf({
    doc: PropTypes.any,
    results: PropTypes.any,
  })).isRequired,
  isLoading: PropTypes.bool,
};

export default GridContent;
