import React from 'react';
import { useQuery } from 'react-query';
import Loader from 'react-loader-spinner';
import { fetchStats } from '../../api/solr';
import { theme } from '../../jsStyles/colorPalette';
import { Stats } from '../../components';

const StatsContainer = () => {
  const {
    data: stats, isLoading, isError,
  } = useQuery('stats', () => fetchStats(),
    {
      refetchOnWindowFocus: false,
    });

  if (isLoading) {
    return <Loader type="ThreeDots" color={theme.colors.primaryLight} height={30} className="loader" />;
  }

  if (isError) {
    return (
      <span>
        Error
      </span>
    );
  }

  return (
    <Stats stats={stats} />
  );
};

export default StatsContainer;
