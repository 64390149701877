import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const Item = styled.div`
    overflow: hidden;
`;

export const Text = styled.p`
    text-align: start;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.light};

    & > em{
        background-color: yellow;
        font-family: ${({ theme }) => theme.fonts.bold};
    }
`;

export const Image = styled.div`
    position: relative;
`;
