import styled from 'styled-components/macro';

export const Container = styled.div`
`;

export const Item = styled.div`
    align-self: flex-start;
    justify-self: flex-start;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const List = styled.div`
    display: grid;
    grid-template-columns: 12rem 1fr;
    gap: 2rem;
    justify-items: flex-start;
    padding: 1rem;

    &:not(:first-of-type){
        border-top: 1px solid ${({ theme }) => theme.colors.gray};
    }
`;

export const Grid = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;
