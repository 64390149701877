import React from 'react';
import PropTypes from 'prop-types';
import FetchErrorContainer from './styles/FetchErrorContainer';

const FetchError = ({ message }) => <FetchErrorContainer>{message}</FetchErrorContainer>;

FetchError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default FetchError;
