import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { FormContext } from '../../contexts/FormContext';
import { removePage } from '../../localstorage/pagination';
import {
  Title,
  FormWrapper,
  LeftSearchFormContent,
  FormikWrapper,
  Input,
  FormButtons,
  InputDateWrapper,
} from './styles/advancedSearch';
import { LatestSearchContext } from '../../contexts/LatestSearchContext';
import { FIELDS } from '../../constants/lastSearchs';
import SelectField from './SelectField';
import { SearchParamsContext } from '../../contexts/SearchParams';
import ROUTES from '../../constants/routes';
import { Button } from '../../shared';
import { deleteEmptyValues } from '../../utils/sanitize';
import { fetchSelectorLists } from '../../api/solr';

const LeftSearchForm = () => {
  const history = useHistory();
  const { selectorLists } = useContext(FormContext);
  const { handleLatestSearchs } = useContext(LatestSearchContext);
  const { dispatchSearchParams, REDUCER_CASES } = useContext(SearchParamsContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  const initialState = {
    initial: state?.search || FIELDS,
    coverage_2_filter: {},
    coverage_2_options: selectorLists.coverage_2,
    matter_filter: {},
    matter_options: selectorLists.matter,
    document_filter: {},
    document_options: selectorLists.document,
  };
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    setFormState({
      ...formState,
      ...initialState,
    });
    removePage();
  }, [state]);

  const listToSelectOptions = (list = []) => list.map((v) => ({ label: v, value: v }));

  // eslint-disable-next-line no-unused-vars
  const getNewFormState = async (filterField, filterValue, dependentFields) => {
    const promises = [];
    const formStateUpdate = { ...formState };
    dependentFields.forEach(async (field) => {
      formStateUpdate[`${field}_filter`][filterField] = filterValue;
      formStateUpdate[`${field}_options`] = fetchSelectorLists(formStateUpdate[`${field}_filter`]);
      promises.push(formStateUpdate[`${field}_options`]);
    });
    await Promise.all(promises);
    dependentFields.forEach((field) => {
      formStateUpdate[`${field}_options`].then((data) => {
        formStateUpdate[`${field}_options`] = data[field];
      });
    });
    return formStateUpdate;
  };

  return (
    <FormWrapper>
      <LeftSearchFormContent>
        <Title>{t('advancedSearch.byFields')}</Title>
        <Formik
          enableReinitialize
          initialValues={formState.initial}
          onSubmit={(values) => {
            dispatchSearchParams({
              type: REDUCER_CASES.addAll,
              payload: {
                value: values,
              },
            });
            const valuesIsEmpty = isEmpty(deleteEmptyValues(values));
            if (!valuesIsEmpty) handleLatestSearchs(values);
            history.push(ROUTES.documents);
          }}
        >
          {({
            handleSubmit, resetForm, values, setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              onReset={resetForm}
            >
              <FormikWrapper>

                <SelectField
                  name="facet_object_institution_desc"
                  options={listToSelectOptions(selectorLists.institution)}
                  placeholder={t('searchForm.institution')}
                  defaultValues={listToSelectOptions(values.facet_object_institution_desc)}
                  setFieldValue={setFieldValue}
                />

                <SelectField
                  name="facet_fond_name"
                  options={listToSelectOptions(selectorLists.fond)}
                  placeholder={t('searchForm.fond')}
                  defaultValues={listToSelectOptions(values.facet_fond_name)}
                  setFieldValue={setFieldValue}
                />

                <SelectField
                  name="facet_object_creator"
                  options={listToSelectOptions(selectorLists.creator)}
                  placeholder={t('searchForm.creator')}
                  defaultValues={listToSelectOptions(values.facet_object_creator)}
                  setFieldValue={setFieldValue}
                />

                <InputDateWrapper>
                  <Input
                    name="object_fromdate"
                    type="number"
                    min="0"
                    max="9999"
                    placeholder={t('searchForm.fromdate')}
                  />
                  <Input
                    name="object_todate"
                    type="number"
                    min="0"
                    max="9999"
                    placeholder={t('searchForm.todate')}
                  />
                </InputDateWrapper>

                <Input
                  name="object_title"
                  type="text"
                  placeholder={t('searchForm.title')}
                  autoComplete="off"
                />

                <Input
                  name="object_desc"
                  type="text"
                  placeholder={t('searchForm.desc')}
                  autoComplete="off"
                />

                <Input
                  name="object_reference"
                  type="text"
                  placeholder={t('searchForm.reference')}
                  autoComplete="off"
                />

                <SelectField
                  name="facet_object_type"
                  options={listToSelectOptions(selectorLists.object_type)}
                  placeholder={t('searchForm.type')}
                  defaultValues={listToSelectOptions(values.facet_object_type)}
                  setFieldValue={setFieldValue}
                />

                <SelectField
                  name="facet_object_language"
                  options={listToSelectOptions(selectorLists.language)}
                  placeholder={t('searchForm.language')}
                  defaultValues={listToSelectOptions(values.facet_object_language)}
                  setFieldValue={setFieldValue}
                />

                <Input
                  name="all_fields"
                  type="text"
                  placeholder={t('searchForm.all')}
                  autoComplete="off"
                />

                &nbsp;
                <br />
                <Title>{t('advancedSearch.fullTextSearch')}</Title>

                <Input
                  name="ocr_text"
                  type="text"
                  placeholder={t('searchForm.ocr')}
                  autoComplete="off"
                />

                <FormButtons>
                  <Button type="submit" onClick={() => {}}>
                    {t('searchForm.search')}
                  </Button>
                  <Button type="reset" onClick={() => setFormState(initialState)}>
                    {t('searchForm.clear')}
                  </Button>
                </FormButtons>

              </FormikWrapper>
            </Form>
          )}
        </Formik>
      </LeftSearchFormContent>
    </FormWrapper>
  );
};

export default LeftSearchForm;
