// eslint-disable-next-line import/prefer-default-export
export const FIELDS = {
  facet_object_institution_desc: [],
  facet_fond_name: [],
  facet_object_creator: [],
  object_fromdate: '',
  object_todate: '',
  object_title: '',
  object_desc: '',
  object_reference: '',
  facet_object_type: [],
  facet_object_language: [],
  all_fields: '',
  ocr_text: '',
};
