import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Result } from '../../shared';

const GridContentSkeletonItem = () => (
  <Result.Grid>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    }}
    >
      <Skeleton height="16rem" width="12rem" variant="rect" />
      <Skeleton height="3.5rem" width="12rem" variant="rect" />
    </div>
  </Result.Grid>
);

export default GridContentSkeletonItem;
