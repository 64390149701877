import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AiFillEye } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { CoverContainer } from '../../containers';
import { CarouselPageCompound } from '..';
import { PageContextProvider } from '../../contexts/PageContext';
import { Button, Result } from '../../shared';
import ListItemInfo from './ListItemInfo';
import { ImageItem } from './styles/documents';
import ROUTES from '../../constants/routes';
import { SearchParamsContext } from '../../contexts/SearchParams';

const ListItem = ({ doc, results }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { searchParams } = useContext(SearchParamsContext);

  const redirect = () => history.push(`${ROUTES.viewer}/${doc.object_name}?highlight=${searchParams.ocr_text}`);

  return (
    <Result.List>
      <PageContextProvider pageID={results[0]?.page || ''}>
        {/* page */}
        <ImageItem>
          {results.length
            ? (
              <CarouselPageCompound
                id={doc.object_uuid}
                path={doc.path}
                results={results}
              />
            )
            : (
              <Link to={`/viewer/${doc.object_name}`}>
                <CoverContainer id={doc.object_uuid} />
              </Link>
            )}
          <Button onClick={redirect}>
            {t('documents.view')}
            <AiFillEye size={18} />
          </Button>
        </ImageItem>
        <ListItemInfo doc={doc} results={results} />
      </PageContextProvider>
    </Result.List>
  );
};

ListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  doc: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.any.isRequired,
};

export default ListItem;
