import React from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentsContainer } from '../../containers';
import {
  Title, DocumentsWrapper, DocumentsContent, DocumentsContentTop,
} from './styles/documents';
import { SearchTerms } from '..';

const DocumentsCompound = () => {
  const { t } = useTranslation();

  return (
    <DocumentsWrapper>
      <DocumentsContent>
        <DocumentsContentTop>
          <Title>{t('documents.title')}</Title>
          <SearchTerms />
        </DocumentsContentTop>
        <DocumentsContainer />
      </DocumentsContent>
    </DocumentsWrapper>
  );
};

export default DocumentsCompound;
