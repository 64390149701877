import React from 'react';
import { PropTypes } from 'prop-types';
import { DrawerContent, DrawerWrapper, Close } from './styles/drawer';

const Drawer = ({ children, isOpen, handleIsOpen }) => (
  <DrawerWrapper isOpen={isOpen} data-testid="drawer">
    <DrawerContent>
      <Close size={30} onClick={() => handleIsOpen((prevState) => !prevState)} data-testid="close-drawer" />
      {children}
    </DrawerContent>
  </DrawerWrapper>
);

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleIsOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Drawer;
