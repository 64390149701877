import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const IsOpenContext = createContext({});

const IsOpenContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <IsOpenContext.Provider value={{
      isOpen, setIsOpen,
    }}
    >
      {children}
    </IsOpenContext.Provider>
  );
};

IsOpenContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { IsOpenContext, IsOpenContextProvider };
