import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import {
  FilterWrapper, Title, FilterOptions, FilterText, FilterCounter,
} from './styles/facets';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { theme } from '../../jsStyles/colorPalette';
import More from './More';

const DISPLAYED_NUM = 5;

const Filter = ({
  facet, category, isLoading, type,
}) => {
  const { dispatchSearchParams } = useContext(SearchParamsContext);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);
  const [isFullyDisplayed, setIsFullyDisplayed] = useState(false);
  const [facetData, setFacetData] = useState(facet);

  const handleDispatch = (dispatchType, dispatchCategory, dispatchValue) => {
    dispatchSearchParams({
      type: dispatchType,
      payload: {
        category: dispatchCategory,
        value: dispatchValue,
      },
    });
  };

  const handleIsFullyDisplayed = () => {
    setIsFullyDisplayed((prevIsFullyDisplayed) => !prevIsFullyDisplayed);
  };

  useEffect(() => {
    if (isFullyDisplayed) {
      setFacetData(facet);
    } else {
      setFacetData(facet.slice(0, DISPLAYED_NUM));
    }
  }, [isFullyDisplayed, facet]);

  return (
    <FilterWrapper>
      <Title
        tabIndex="0"
        role="menu"
        aria-label={t(category)}
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        onKeyUp={(e) => {
          if ([' ', 'Enter'].includes(e.key)) { setIsOpen((prevIsOpen) => !prevIsOpen); }
        }}
      >
        {t(category)}
      </Title>
      {facetData.map((f) => {
        const [value, count] = f;
        return (
          <FilterOptions
            onClick={() => handleDispatch(type, category, value)}
            onKeyUp={(e) => {
              if ([' ', 'Enter'].includes(e.key)) { handleDispatch(type, category, value); }
            }}
            isOpen={isOpen}
            key={value}
            tabIndex="0"
            aria-label={value}
            role="menuitem"
            title={value}
          >
            <FilterText>{value}</FilterText>
            {isLoading
              ? <Loader type="Oval" height={15} color={theme.colors.primaryLight} className="loader_small" />
              : (
                <FilterCounter>{count}</FilterCounter>
              )}
          </FilterOptions>
        );
      })}
      {facetData.length >= DISPLAYED_NUM && (
      <More
        isFullyDisplayed={isFullyDisplayed}
        handleIsFullyDisplayed={handleIsFullyDisplayed}
        isOpen={isOpen}
      />
      )}
    </FilterWrapper>
  );
};

Filter.propTypes = {
  facet: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ).isRequired,
  category: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default Filter;
