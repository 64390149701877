import React from 'react';
import PropTypes from 'prop-types';
import { Image } from './styles/pageCover';

const PageCover = ({ cover }) => {
  const {
    vol_path: volPath, file_path_type: filePathType, file_path: filePath, file_name: fileName,
  } = cover;

  if (filePathType !== 'url') {
    const url = encodeURIComponent(`${volPath}/${filePath}${fileName}`);
    return (
      <Image src={`${process.env.REACT_APP_IMAGE_SERVER}/${url}/full/192,260/0/default.jpg`} />
    );
  }

  const url = encodeURIComponent(filePath);
  return (
    <Image src={`${process.env.REACT_APP_IMAGE_SERVER}/${url}/full/192,260/0/default.jpg`} />
  );
};

PageCover.propTypes = {
  cover: PropTypes.shape({
    vol_path: PropTypes.string,
    file_path_type: PropTypes.string,
    file_path: PropTypes.string,
    file_name: PropTypes.string,
  }).isRequired,
};

export default PageCover;
