import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCloseCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { SearchParamsContext } from '../../contexts/SearchParams';
import {
  Tag, Label, Value,
} from './styles/searchTerms';

const MultiTerms = ({ category, values }) => {
  const { dispatchSearchParams, REDUCER_CASES } = useContext(SearchParamsContext);
  const { t } = useTranslation();

  return (
    <>
      {values.map((value) => (
        <Tag
          key={value}
          onClick={() => dispatchSearchParams({
            type: REDUCER_CASES.removeMultiField,
            payload: {
              category,
              value,
            },
          })}
        >
          <Label>
            {t(category)}
            :
          </Label>
          <Value>{value}</Value>
          <AiFillCloseCircle size={16} />
        </Tag>
      ))}
    </>
  );
};

MultiTerms.propTypes = {
  category: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired,
};

export default MultiTerms;
