import styled from 'styled-components/macro';

const Img = styled.div`
    display: block;
    background: url(${({ url }) => url});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    width: 12rem;
    height: 16rem;
    margin-bottom: 1rem;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background: ${({ theme }) => `radial-gradient(circle, ${theme.colors.grayLight} 0%, ${theme.colors.grayDark} 100%)`} ;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        opacity: 50%;
    }
`;

export default Img;
