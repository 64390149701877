import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { PageContext } from '../../contexts/PageContext';
import { fetchSnippets } from '../../api/solr';
import { CarouselSnippetsCompound } from '../../components';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { ConfContext } from '../../contexts/ConfContext';

const SnippetsContainer = ({ id }) => {
  const { env } = useContext(ConfContext);
  const { page } = useContext(PageContext);
  const { searchParams } = useContext(SearchParamsContext);

  const { max_results_outcut: outcut } = env;

  const {
    data, error, isLoading, isError, isFetching,
  } = useQuery(
    [`snippets-${page}`, searchParams, page, id],
    () => fetchSnippets(id, page, searchParams, outcut.var_value),
    { refetchOnWindowFocus: false },
  );

  if (isLoading || isFetching) {
    return (
      <>
        <Skeleton width="100%" height="10rem" variant="rect" />
      </>
    );
  }

  if (isError) {
    return (
      <span>
        Error:
        {error}
      </span>
    );
  }

  return (
    <CarouselSnippetsCompound data={data} />
  );
};

SnippetsContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SnippetsContainer;
