import React, {
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { init } from 'universalviewer';

const Viewer = ({
  manifest, width, height, highlight,
}) => {
  const { i18n } = useTranslation();

  const el = useRef();

  useEffect(() => {
    const uv = init(el.current,
      {
        manifestUri: manifest,
        configUri: `../uv-assets/config/uv-openseadragon-extension.${i18n.language}.config.json`,
        highlight,
      });

    uv.on('created', () => {
      uv.resize();
    }, false);
  }, [manifest, i18n.language]);

  return (
    <div
      ref={el}
      id="uv"
      className="uv"
      style={{
        width,
        height,
      }}
    />
  );
};

Viewer.defaultProps = {
  width: '100vw',
  height: '100vh',
  highlight: '',
};

Viewer.propTypes = {
  manifest: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  highlight: PropTypes.string,
};

export default Viewer;
