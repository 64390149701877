import axios from 'axios';
import Cookies from 'universal-cookie';

let api = null;

const getHeaders = async () => {
  const cookies = new Cookies();
  const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'X-CSRF-TOKEN': cookies.get('csrf_access_token'),
  };
  return headers;
};

const getInitializedApi = () => {
  if (api) {
    return api;
  }
  api = axios.create({
    responseType: 'json',
    withCredentials: true,
  });
  return api;
};

export const get = async (url, data) => {
  const headers = await getHeaders();
  return getInitializedApi().get(url, { headers, params: data });
};

export const post = async (url, data, options) => {
  const headers = await getHeaders();
  return getInitializedApi().post(url, data, { headers, ...options });
};

export const remove = async (url, data = null, options) => {
  const headers = await getHeaders();
  return getInitializedApi().delete(url, {
    ...options,
    headers,
    data,
  });
};

export const del = async (url, data = null, options) => {
  const headers = await getHeaders();
  return getInitializedApi().delete(url, {
    ...options,
    headers,
    data,
  });
};

export const postMultiPart = async (url, data) => {
  const headers = await getHeaders();
  return getInitializedApi().post(url, data, { headers });
};

export const put = async (url, data) => {
  const headers = await getHeaders();
  return getInitializedApi().put(url, data, { headers });
};

export const uploadMedia = async (url, file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return postMultiPart(url, bodyFormData);
};

export const download = async (url, contentType) => {
  let headers = await getHeaders(url);
  if (contentType) {
    headers = {
      ...headers,
      'Content-Type': contentType,
      Accept: contentType,
    };
  }
  return getInitializedApi().get(url, {
    headers,
    responseType: 'blob',
    data: {},
  });
};
