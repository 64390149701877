import { get, post } from './restClient';
import { postAudit } from './db';
import { EVENTS } from '../constants/events';
import ROUTES from '../constants/routes';

export const USER_STORAGE_KEY = 'user';

export const getUser = () => JSON.parse(window.localStorage.getItem(USER_STORAGE_KEY));
export const setUser = (user) => window.localStorage.setItem(USER_STORAGE_KEY, user);
export const removeUser = () => window.localStorage.removeItem(USER_STORAGE_KEY);

export const userAssigned = () => !!getUser();
export const logout = async () => {
  const { data } = await post(`${process.env.REACT_APP_AUTH_SERVER}/logout`);
  if (data) {
    removeUser();
  }
};

export const fetchUserData = async () => {
  const { data } = await get(`${process.env.REACT_APP_AUTH_SERVER}/user`);
  if (data) {
    return data;
  }
  removeUser();
  window.location.replace(ROUTES.home);
  throw new Error('Token not valid error');
};

export const login = async (credentials) => {
  const { data } = await post(`${process.env.REACT_APP_AUTH_SERVER}/login`, credentials);
  if (data) {
    const user = await fetchUserData();
    setUser(JSON.stringify(user));
    postAudit(EVENTS.evt_login, user.id);
    return;
  }
  throw new Error('Wrong Credentials');
};

export const verifyCASTicket = async (ticket) => {
  const { data } = await get(`${process.env.REACT_APP_CAS_LOGIN_URL}`, { ticket });
  if (data) {
    const user = await fetchUserData();
    setUser(JSON.stringify(user));
    postAudit(EVENTS.evt_login, user.id);
    return;
  }
  throw new Error('There was a problem verifying the CAS ticket or obtaining identity!');
};
