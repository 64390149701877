export const LATEST_SEARCH_LIST_KEY = 'latestSearchList';

export const getLatestSearchList = () => {
  const latestSearchList = JSON.parse(window.localStorage.getItem(LATEST_SEARCH_LIST_KEY)) || [];
  return latestSearchList;
};
export const setLatestSearchList = (search) => {
  const searchList = getLatestSearchList();
  const pre = searchList.slice(0, 4);
  window.localStorage.setItem(LATEST_SEARCH_LIST_KEY, JSON.stringify([search, ...pre]));
};
export const removeLatestSearchList = () => window.localStorage.removeItem(LATEST_SEARCH_LIST_KEY);
