import React from 'react';
import { useTranslation } from 'react-i18next';
import AdvancedSearchCompund from '../components/advancedSearch';

const Search = () => {
  const { t } = useTranslation();

  document.title = [
    process.env.REACT_APP_NAME,
    t('search'),
  ].join(' · ');

  return <AdvancedSearchCompund />;
};

export default Search;
