import { get, post } from './restClient';

export const fetchDocument = async (name) => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/object?name=${name}`);
  if (data) {
    return data;
  }
  throw new Error('fetchDocument error');
};

export const fetchPresets = async () => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/presets`);
  if (data) {
    return data;
  }
  throw new Error('fetchPresets error');
};

export const fetchCover = async (objectId) => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/cover?id=${objectId}`);
  if (data) {
    return data;
  }
  throw new Error('fetchCover error');
};

export const fetchRights = async (right) => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/rights?right=${right}`);
  if (data) {
    return data;
  }
  throw new Error('fetchRights error');
};

export const fetchOldestDay = async () => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/oldest`);
  if (data) {
    return data;
  }
  throw new Error('fetchOldestDay error');
};

export const fetchMonthDates = async (startMonth, endMonth) => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/monthdates?start=${startMonth}&end=${endMonth}`);
  if (data) {
    return data;
  }
  throw new Error('fetchMonthDates error');
};

export const fetchNameByDate = async (date) => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/namebydate?date=${date}`);
  if (data) {
    return data;
  }
  throw new Error('fetchNameByDate error');
};

export const postAudit = async (event, payload) => {
  await post(`${process.env.REACT_APP_DB_SERVER}/audits`, JSON.stringify({ event, payload }));
};

export const fetchConf = async () => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/env`);
  if (data) {
    return data;
  }
  throw new Error('fetchConf error');
};

export const fetchPageCover = async (fileCode) => {
  const { data } = await get(`${process.env.REACT_APP_DB_SERVER}/page-cover?file-code=${fileCode}`);
  if (data) {
    return data;
  }
  throw new Error('fetchPageCover error');
};
