import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function RightContent({ right }) {
  const { right_logo: logo, right_url: rightUrl } = right;

  return (
    <Link to={{ pathname: rightUrl }} target="_blank">
      <img src={`${process.env.REACT_APP_STORAGE_SERVER}/public/logos/${logo}`} alt="right_logo" />
    </Link>
  );
}

RightContent.propTypes = {
  right: PropTypes.string.isRequired,
};

export default RightContent;
