import styled from 'styled-components/macro';
import { MdClose } from 'react-icons/md';

export const DrawerWrapper = styled.div`
    position: fixed;
    height: 100vh;
    z-index: 11;
    background-color: ${({ theme }) => theme.colors.light};
    top: 0;
    box-shadow: .1rem .1rem 1rem rgba(0,0,0,0.5);
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    overflow-y: scroll;
    direction: rtl;
`;

export const DrawerContent = styled.div`
    position: relative;
    flex-direction: column;
    width: 20rem;
    direction: ltr;
`;

export const Close = styled(MdClose)`
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    fill: ${({ theme }) => theme.colors.text};

    &:hover{
        cursor: pointer;
        fill: ${({ theme }) => theme.colors.primaryLight};
    }
`;
