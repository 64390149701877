import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { fetchCover } from '../../api/db';
import { Cover } from '../../components';

const CoverContainer = ({ id }) => {
  const {
    data: covers, error, isError,
  } = useQuery(`cover-${id}`, () => fetchCover(id), { refetchOnWindowFocus: false });

  const [preCovers, setPreCovers] = useState([
    {
      file_name: '',
      file_path: '',
      file_path_type: '',
      vol_path: '',
    },
  ]);

  useLayoutEffect(() => {
    if (covers && covers.length > 0) setPreCovers(covers);
  }, [covers]);

  if (isError) {
    return (
      <span>
        Error:
        {error}
      </span>
    );
  }

  return (
    <Cover cover={preCovers[0]} />
  );
};

CoverContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CoverContainer;
