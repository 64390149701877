import { MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';

export const LoginContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Button = styled.button`
    padding: 0 1rem;
    color: ${({ theme }) => theme.colors.light};
    font-family: ${({ theme }) => theme.fonts.normal};
    background-color: transparent;
    border: none;
    height: 48px;

    &:hover{
        cursor: pointer;
        background-color: rgba(0,0,0,.2)
    }
`;

export const CloseButton = styled(MdClose)`
    fill: ${({ theme }) => theme.colors.text};
    align-self: flex-end;

    &:hover{
        cursor: pointer;
        fill: ${({ theme }) => theme.colors.primaryLight};
    }
`;
