import React from 'react';
import PropTypes from 'prop-types';
import { SelectWrapper } from './styles/advancedSearch';

const SelectField = ({
  name, options, placeholder, defaultValues, setFieldValue,
  formState, setFormState, getNewFormState, dependentFields,
}) => (
  <SelectWrapper
    name={name}
    options={options}
    placeholder={placeholder}
    aria-label={placeholder}
    tabIndex="0"
    isMulti
    value={defaultValues}
    onChange={(selected) => {
      const selectedValues = selected.map(({ value }) => value);
      setFieldValue(name, selectedValues);
      if (typeof getNewFormState === 'function' && dependentFields.length > 0) {
        getNewFormState(name, selectedValues, dependentFields)
          .then((formStateUpdate) => setFormState({ ...formState, ...formStateUpdate }));
      }
    }}
    data-testid="select"
  />
);

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  formState: PropTypes.shape({}),
  setFormState: PropTypes.func,
  getNewFormState: PropTypes.func,
  dependentFields: PropTypes.arrayOf(PropTypes.string),
};

SelectField.defaultProps = {
  formState: {},
  setFormState: null,
  getNewFormState: null,
  dependentFields: [],
};

export default SelectField;
