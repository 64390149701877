import React from 'react';
import PropTypes from 'prop-types';
import {
  InfoContainer, InfoRow, InfoText, ListItemTitle,
} from './styles/documents';
import { SnippetsContainer } from '../../containers';

function ListItemInfo({ doc, results }) {
  return (
    <InfoContainer>
      <ListItemTitle to={{ pathname: `/document/${doc.object_name}` }}>
        {doc.object_title}
      </ListItemTitle>

      <InfoText>
        {`${doc.fond_name} > ${doc.object_reference} > ${doc.object_identifier}`}
      </InfoText>
      <InfoText>{doc.object_creator}</InfoText>

      <InfoRow>
        <InfoText>
          {
            (doc.object_data
            && doc.object_data.split('T')[0])
            || doc.object_data_notes
            || `${doc.object_fromdate || ''}-${doc.object_todate || ''}`
          }
        </InfoText>
        <InfoText>{doc.object_type}</InfoText>
        <InfoText>{doc.object_language}</InfoText>
      </InfoRow>

      {/* snippets */}
      {results.length
        ? <SnippetsContainer key={doc.object_uuid} id={doc.object_uuid} />
        : null}
    </InfoContainer>
  );
}

ListItemInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  doc: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.any.isRequired,
};

export default ListItemInfo;
