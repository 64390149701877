import React, { useContext } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.css';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { PageContext } from '../../contexts/PageContext';
import { Highlight } from '..';
import { Snippets } from '../../shared';
import { PageCoverContainer } from '../../containers';

const CarouselPageCompound = ({
  results,
}) => {
  const { handleSnippetIdx, handlePageChange } = useContext(PageContext);

  return (
    <Carousel
      showStatus
      statusFormatter={(currentItem, total) => `${currentItem}/${total}`}
      onChange={(current) => handlePageChange(results[current].page)}
    >
      {results.slice(0, 5).map((p) => (
        <Snippets page={p.page}>
          <div style={{ position: 'relative' }}>
            <PageCoverContainer fileCode={p.page} />
            {p.snippets.map((s, idx) => s.highlights.map((h) => (
              <Highlight onClick={() => handleSnippetIdx(idx)}>
                <Highlight.Item
                  x={h.xpct}
                  y={h.ypct}
                  w={h.wpct}
                  h={h.hpct}
                />
              </Highlight>
            )))}
          </div>
        </Snippets>
      ))}
    </Carousel>
  );
};

CarouselPageCompound.defaultProps = {
  results: [],
};

CarouselPageCompound.propTypes = {
  results: PropTypes.arrayOf,
};

export default CarouselPageCompound;
