import React from 'react';
import PropTypes from 'prop-types';
import InfoTable from './Table';
import { Container } from './styles/document';

const DocumentInfo = ({ info }) => (
  <Container>
    <InfoTable info={info} />
  </Container>
);

DocumentInfo.propTypes = {
  info: PropTypes.objectOf.isRequired,
};

export default DocumentInfo;
