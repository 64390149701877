import React from 'react';
import PropTypes from 'prop-types';
import {
  BreadcrumbWrapper, Container, ButtonLink, Text, Start,
} from './styles/breadcrumb';

const Breadcrumb = ({ children }) => (
  <BreadcrumbWrapper>
    <Container>
      {children}
    </Container>
  </BreadcrumbWrapper>
);

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
};

Breadcrumb.Link = ({ to, text }) => (
  <ButtonLink to={to}>
    {text}
  </ButtonLink>
);

Breadcrumb.Link.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

Breadcrumb.Text = ({ text }) => (
  <Text>{text}</Text>
);

Breadcrumb.Text.propTypes = {
  text: PropTypes.string.isRequired,
};

Breadcrumb.Start = ({ children }) => (
  <Start data-testid="start">{children}</Start>
);

Breadcrumb.Start.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Breadcrumb;
