import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillHome } from 'react-icons/ai';
import { Documents } from '../components';
import { Breadcrumb } from '../shared';

const DocumentsPage = () => {
  const { t } = useTranslation();

  document.title = [
    process.env.REACT_APP_NAME,
    t('breadcrumb.results'),
  ].join(' · ');

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Start>
          <AiFillHome size={20} />
        </Breadcrumb.Start>
        <Breadcrumb.Text text={t('breadcrumb.results')} />
      </Breadcrumb>
      <Documents />
    </>
  );
};

export default DocumentsPage;
