import React, { useContext } from 'react';
import { IsOpenContext } from '../../contexts/IsOpenContext';
import { Drawer } from '../../shared';
import SearchList from './SearchList';

const LastSearchs = () => {
  const { isOpen, setIsOpen } = useContext(IsOpenContext);

  return (
    <Drawer isOpen={isOpen} handleIsOpen={setIsOpen}>
      <SearchList />
    </Drawer>
  );
};

export default LastSearchs;
