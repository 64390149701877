import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSelect } from './styles/languageSelector';

const labels = {
  'es-ES': 'Castellano',
  'en-GB': 'English',
  'gl-ES': 'Galego',
};

const options = Object.entries(labels).map((x) => ({ value: x[0], label: x[1] }));

const htmlDeclaration = {
  'es-ES': 'es',
  'en-GB': 'en',
  'gl-ES': 'gl',
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  window.document.children[0].setAttribute('lang', htmlDeclaration[i18n.language]);
  window.document.children[0].setAttribute('xml:lang', htmlDeclaration[i18n.language]);

  return (
    <LanguageSelect
      options={options}
      onChange={(e) => changeLanguage(e.value)}
      isSearchable={false}
      defaultValue={{
        value: i18n.language,
        label: labels[i18n.language],
      }}
      className="language"
      classNamePrefix="language"
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
    />
  );
};

export default LanguageSelector;
