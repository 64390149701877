import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { postAudit } from '../api/db';
import { EVENTS } from '../constants/events';

const PageContext = createContext({});

const PageContextProvider = ({ children, pageID }) => {
  const [page, setPage] = useState(pageID);
  const [snippetIndex, setSnippetIndex] = useState(0);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setSnippetIndex(0);
    postAudit(EVENTS.evt_navigate_thmb, newPage);
  };

  const handleSnippetIdx = (newIdx) => {
    setSnippetIndex(newIdx);
    postAudit(EVENTS.evt_navigate_cutout, newIdx);
  };

  return (
    <PageContext.Provider value={{
      page, snippetIndex, handleSnippetIdx, handlePageChange,
    }}
    >
      {children}
    </PageContext.Provider>
  );
};

PageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  pageID: PropTypes.string.isRequired,
};

export { PageContext, PageContextProvider };
