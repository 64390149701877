import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Result } from '../../shared';

const ListContentSkeletonItem = () => (
  <Result.List>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
    }}
    >
      <Skeleton height="16rem" width="100%" variant="rect" />
      <Skeleton height="2rem" width="100%" variant="rect" />
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
    }}
    >
      <Skeleton height="2rem" width="50%" variant="rect" />
      <Skeleton height="5rem" width="80%" variant="rect" />
    </div>
  </Result.List>
);

export default ListContentSkeletonItem;
