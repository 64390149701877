/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Item } from './styles/highlight';

const Highlight = ({ children, ...restprops }) => (
  <Container {...restprops} data-testid="highlight">
    {children}
  </Container>
);

Highlight.propTypes = {
  children: PropTypes.node.isRequired,
};

Highlight.Item = ({
  x, y, w, h,
}) => (
  <Item x={x} y={y} w={w} h={h} />
);

Highlight.Item.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
};

export default Highlight;
