import React from 'react';
import { useQuery } from 'react-query';
import Loader from 'react-loader-spinner';
import { fetchOldestDay } from '../../api/db';
import CalendarSearchForm from '../../components/advancedSearch/CalendarSearchForm';
import { theme } from '../../jsStyles/colorPalette';

const CalendarContainer = () => {
  const {
    data: oldest, isError, isLoading,
  } = useQuery('oldest', () => fetchOldestDay(), { refetchOnWindowFocus: false });

  if (isLoading) {
    return <Loader type="ThreeDots" height={30} color={theme.colors.primaryLight} className="loader" />;
  }

  if (isError) {
    return <></>;
  }

  return (
    <CalendarSearchForm oldest={oldest} />
  );
};

export default CalendarContainer;
