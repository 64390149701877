import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ListContent from './ListContent';
import GridContent from './GridContent';
import { DocumentsContentBottom, DocumentsResults } from './styles/documents';
import Header from './Header';
import { Facets, Sort } from '..';

const DocumentsContent = ({
  data, isLoading,
}) => {
  const [showOption, setShowOption] = useState('list');

  const { documents, facets, hits } = data;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [documents]);
  return (
    <DocumentsContentBottom>
      <header>
        <Facets data={facets} isLoading={isLoading} />
      </header>
      <DocumentsResults>
        <Header
          showOption={showOption}
          setShowOption={setShowOption}
          hits={hits}
        />
        <Sort />
        <main>
          {showOption === 'list'
            ? <ListContent data={documents} isLoading={isLoading} />
            : <GridContent data={documents} isLoading={isLoading} />}
        </main>
        <Header
          showOption={showOption}
          setShowOption={setShowOption}
          hits={hits}
        />
      </DocumentsResults>
    </DocumentsContentBottom>

  );
};

DocumentsContent.propTypes = {
  data: PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.shape({
      doc: PropTypes.shape({
        object_uuid: PropTypes.string,
      }),
    })),
    facets: PropTypes.shape({}),
    hits: PropTypes.number,
    qTime: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DocumentsContent;
