/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import {
  Table, TBody, Row, Field, ButtonContainer, SearchItemWrapper, Date,
} from './styles/drawer';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { LatestSearchContext } from '../../contexts/LatestSearchContext';
import { IsOpenContext } from '../../contexts/IsOpenContext';
import ROUTES from '../../constants/routes';
import { Button } from '../../shared';

const SearchItem = ({ searchParams }) => {
  const { date, search } = searchParams;
  const { t } = useTranslation();
  const { handleLatestSearchs } = useContext(LatestSearchContext);
  const { dispatchSearchParams, REDUCER_CASES } = useContext(SearchParamsContext);
  const { setIsOpen } = useContext(IsOpenContext);
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const emptyValues = (values) => values.filter(([_k, v]) => !isEmpty(v));

  const sanitizedSearch = emptyValues(Object.entries(search));

  const handleSearch = (values) => {
    dispatchSearchParams({
      type: REDUCER_CASES.addAll,
      payload: {
        value: values,
      },
    });
    handleLatestSearchs(values);
    setIsOpen(false);
    history.push(ROUTES.documents);
  };

  const handleEdit = () => {
    setIsOpen(false);
    history.push({ pathname: ROUTES.search, state: searchParams });
  };

  return (
    <SearchItemWrapper>
      <Date>{date}</Date>
      <Table>
        <TBody>
          {sanitizedSearch.map(([k, v]) => (
            <Row key={k}>
              <Field>{t(k)}</Field>
              <Field>{Array.isArray(v) ? v.join(', ') : v}</Field>
            </Row>
          ))}
        </TBody>
      </Table>
      <ButtonContainer>
        <Button onClick={() => handleSearch(search)}>{t('latestSearches.search')}</Button>
        <Button onClick={() => handleEdit(search)}>{t('latestSearches.edit')}</Button>
      </ButtonContainer>
    </SearchItemWrapper>
  );
};

SearchItem.propTypes = {
  searchParams: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string,
    search: PropTypes.shape({
      ocr_text: PropTypes.string,
      object_fromdate: PropTypes.string,
      object_todate: PropTypes.string,
      object_title: PropTypes.string,
      object_desc: PropTypes.string,
      object_reference: PropTypes.string,
      object_type: PropTypes.string,
      object_language: PropTypes.arrayOf(PropTypes.string),
      all_fields: PropTypes.string,
      fond_name: PropTypes.string,
      object_creator: PropTypes.string,
      object_institution_desc: PropTypes.string,
      year: PropTypes.string,
    }),
  }).isRequired,
};

export default SearchItem;
