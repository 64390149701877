import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Hero } from '../components';
import { FIELDS } from '../constants/lastSearchs';
import { SearchParamsContext } from '../contexts/SearchParams';
import ROUTES from '../constants/routes';

const ENTER_KEY = 'Enter';

const Home = () => {
  const history = useHistory();
  const { dispatchSearchParams, REDUCER_CASES } = useContext(SearchParamsContext);

  useEffect(() => {
    dispatchSearchParams({
      type: REDUCER_CASES.addAll,
      payload: {
        value: {
          ...FIELDS,
        },
      },
    });

    const enterKeyListener = (e) => {
      if (e.key === ENTER_KEY) {
        history.push(ROUTES.documents);
      }
    };

    window.addEventListener('keyup', enterKeyListener);
    return () => window.removeEventListener('keyup', enterKeyListener);
  }, []);

  return (
    <Hero />
  );
};

export default Home;
