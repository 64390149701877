import React from 'react';
import PropTypes from 'prop-types';
import ShowOptions from './ShowOptions';
import Pagination from './Pagination';
import { HeaderWrapper } from './styles/documents';
import Founds from './Founds';

const Header = ({
  showOption, setShowOption, hits,
}) => (
  <HeaderWrapper>
    <Founds length={hits} />
    <Pagination hits={hits} />
    <ShowOptions showOption={showOption} setShowOption={setShowOption} />
  </HeaderWrapper>
);

Header.propTypes = {
  showOption: PropTypes.string.isRequired,
  setShowOption: PropTypes.func.isRequired,
  hits: PropTypes.number.isRequired,
};

export default Header;
