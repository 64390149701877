import React from 'react';
import SortList from './SortList';
import { SortWrapper } from './styles/sort';

const Sort = () => (
  <SortWrapper>
    <SortList />
  </SortWrapper>
);

export default Sort;
