import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const InputField = ({
  name, className, placeholder, autoComplete,
}) => (
  <Field
    type="text"
    name={name}
    className={className}
    placeholder={placeholder}
    autoComplete={autoComplete}
    tabIndex="0"
    aria-label={placeholder}
  />
);

InputField.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

InputField.defaultProps = {
  autoComplete: 'off',
  className: '',
};

export default InputField;
