import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { Button } from '../../shared';
import { CloseButton } from './styles/login';
import { login } from '../../api/auth';
import { LoginContext } from '../../contexts/LoginContext';

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .min(4, 'Must be at least 4 characters')
    .required('Username is required'),
  password: Yup.string()
    .min(4, 'Password must be at least 4 characters')
    .required('Password is required'),
});

const LoginForm = ({ handleModal }) => {
  const { t } = useTranslation();
  const { setIsLogged } = useContext(LoginContext);
  const history = useHistory();

  const [error, setError] = useState('');

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={LoginSchema}
      onSubmit={async (credentials) => {
        setError('');
        login(credentials)
          .then(() => {
            setIsLogged(true);
            handleModal();
            history.push(ROUTES.search);
            window.location.reload();
          })
          .catch((err) => {
            if (err && err.response) {
              setError(err.response.data.msg);
              setIsLogged(false);
            }
          });
      }}
    >
      {({ errors, handleSubmit }) => (
        <Form className="login-form" onSubmit={handleSubmit}>
          <CloseButton onClick={() => handleModal()} size={28} />
          <h2 className="login-form__title">{t('login.title')}</h2>
          {error
            ? (
              <span className="error-msg">
                <span>{error}</span>
              </span>
            ) : null}
          <Field
            className={`login-form__input ${errors.username && 'error'}`}
            name="username"
            type="text"
            placeholder={t('login.username')}
            autoComplete="off"
          />
          {errors.username
            ? (
              <span className="error-msg">
                <ErrorMessage name="username" />
              </span>
            ) : null}

          <Field
            className={`login-form__input ${errors.password && 'error'}`}
            name="password"
            type="password"
            placeholder={t('login.password')}
            autoComplete="off"
          />
          {errors.password
            ? (
              <span className="error-msg">
                <ErrorMessage name="password" />
              </span>
            ) : null}
          <Button type="submit">{t('login.submit')}</Button>
        </Form>
      )}

    </Formik>
  );
};

LoginForm.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

export default LoginForm;
