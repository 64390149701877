import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import Loader from 'react-loader-spinner';
import { fetchSelectorLists } from '../api/solr';
import { theme } from '../jsStyles/colorPalette';

const FormContext = createContext({});

const FormContextProvider = ({ children }) => {
  const {
    data, error, isLoading, isError,
  } = useQuery(['selectorLists'], () => fetchSelectorLists(), { refetchOnWindowFocus: false });

  if (isLoading) {
    return <Loader type="ThreeDots" height={30} color={theme.colors.primaryLight} className="loader" />;
  }

  if (isError) {
    return (
      <span>
        Error:
        {error}
      </span>
    );
  }

  return (
    <FormContext.Provider value={{ selectorLists: data }}>
      {children}
    </FormContext.Provider>
  );
};

FormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FormContext, FormContextProvider };
