import React from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { MoreWrapper } from './styles/facets';

const More = ({ isFullyDisplayed, handleIsFullyDisplayed, isOpen }) => (
  <MoreWrapper
    onClick={() => handleIsFullyDisplayed()}
    onKeyUp={(e) => {
      if ([' ', 'Enter'].includes(e.key)) { handleIsFullyDisplayed(); }
    }}
    isOpen={isOpen}
    tabIndex="0"
    role="button"
  >
    {isFullyDisplayed
      ? <FiMinus size={18} aria-label="-" />
      : <FiPlus size={18} aria-label="+" />}
  </MoreWrapper>
);

More.propTypes = {
  isFullyDisplayed: PropTypes.bool.isRequired,
  handleIsFullyDisplayed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default More;
