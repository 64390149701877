import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Item, Text, Image,
} from './styles/snippet';

const Snippets = ({ children, page }) => (
  <Container page={page} data-testid="Snippets">
    {children}
  </Container>
);

Snippets.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
};

Snippets.Item = ({ children }) => (
  <Item>{children}</Item>
);

Snippets.Item.propTypes = {
  children: PropTypes.node.isRequired,
};

Snippets.Text = ({ text }) => (
  <Text dangerouslySetInnerHTML={{ __html: text }} />
);

Snippets.Text.propTypes = {
  text: PropTypes.string.isRequired,
};

Snippets.Image = ({
  children, className, src, alt,
}) => (
  <Image className={className}>
    <img src={src} alt={alt} />
    {children}
  </Image>
);

Snippets.Image.defaultProps = {
  className: '',
};

Snippets.Image.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Snippets;
