import React, { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import { Container } from './styles/snippets';
import { Highlight } from '..';
import { PageContext } from '../../contexts/PageContext';
import { Snippets } from '../../shared';
import { SnippetCoverContainer } from '../../containers';

const CarouselSnippetsCompound = ({
  data, className,
}) => {
  const { snippetIndex, handleSnippetIdx } = useContext(PageContext);
  const { snippets } = data[0];

  return (
    <Container className={className}>
      <Carousel
        selectedItem={snippetIndex}
        statusFormatter={(currentItem, total) => `${currentItem}/${total}`}
        onChange={(current) => handleSnippetIdx(current)}
      >
        {snippets.map((s) => {
          const coordinates = {
            rx: s.rx, ry: s.ry, rw: s.rw, rh: s.rh,
          };
          return (
            <Snippets>
              <Snippets.Item page={s.page} key={`${s.rx}${s.ry}${s.rw}${s.rh}`}>
                <Snippets.Text text={s.text} />
                <div style={{ position: 'relative' }}>
                  <SnippetCoverContainer fileCode={s.page} coordinates={coordinates} />
                  {s.highlights.map((h) => (
                    <Highlight>
                      <Highlight.Item x={h.xpct} y={h.ypct} w={h.wpct} h={h.hpct} />
                    </Highlight>
                  ))}
                </div>
              </Snippets.Item>
            </Snippets>
          );
        })}
      </Carousel>
    </Container>
  );
};

CarouselSnippetsCompound.defaultProps = {
  className: '',
};

CarouselSnippetsCompound.propTypes = {
  data: PropTypes.arrayOf.isRequired,
  className: PropTypes.string,
};

export default CarouselSnippetsCompound;
