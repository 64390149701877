import styled from 'styled-components/macro';

export const SearchListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: .5rem;
    background-color: ${({ theme }) => theme.colors.gray};
    padding-bottom: .5rem;
`;

export const Title = styled.h3`
    font-family: ${({ theme }) => theme.fonts.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    margin-left: 1rem;
`;

export const SearchItemWrapper = styled.div`
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.light};
    margin: 0 .5rem;
    font-size: 0.9rem;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
`;

export const Date = styled.p`
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
`;

export const Table = styled.table`
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
    border-collapse: collapse;
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.colors.text};
    margin: 1rem 0;
`;

export const TBody = styled.tbody``;

export const Row = styled.tr`
    border: none;
    
    &:nth-of-type(even){
        background-color: ${({ theme }) => theme.colors.gray};
    }
`;

export const Field = styled.td`
    padding: 0.3rem 1rem;
    vertical-align: top;
    &:first-of-type{
        border-right: 1px solid ${({ theme }) => theme.colors.grayDark};
    }
`;

export const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    gap: 1rem;
`;
