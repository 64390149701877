import styled from 'styled-components/macro';

export const Background = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    background: url(${({ background }) => (background)}) center center / cover no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const HeroContent = styled.div`
    position: relative;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.7);
    text-align: center;
`;

export const Title = styled.h1`
    font-family: ${({ theme }) => theme.fonts.italic};
    font-size: 3rem;
    margin: 0rem;
    color: ${({ theme }) => theme.colors.title};
`;

export const Subtitle = styled.h3`
    font-family: ${({ theme }) => theme.fonts.normal};
    font-size: 1.5rem;
    margin: 0 0 3rem;
    color: ${({ theme }) => theme.colors.title};
`;

export const SearchbarWrapper = styled.div`
    display: flex;
    height: 2.5rem;
    position: relative;

    &::before,
    &::after{
        content: '';
        height: 2.5rem;
        width: 1.5rem;
        background-color: ${({ theme }) => theme.colors.light};
        position: absolute;
        z-index: 10;
    }

    &::before{
        left: 0;
        transform: translateX(-100%);
    }

    &::after{
        right: 0;
        transform: translateX(100%);
    }
`;

export const Input = styled.input`
    outline: none;
    width: 20rem;
    border: none;
    font-family: ${({ theme }) => theme.fonts.normal};
    position: relative;
`;

export const Attribution = styled.div`
    position: absolute;
    font-family: ${({ theme }) => theme.fonts.normal};
    font-size: .9rem;
    background-color: rgba(0,0,0,0.5);
    padding: .5rem 1rem;
    bottom: 0;
    left: 0;
    margin: 1rem;
    color: rgba(255,255,255,0.8);

    & > * {
        color: ${({ theme }) => theme.colors.light};
        text-decoration: none;
    }

`;
