import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import ListItem from './ListItem';
import { ListContainer } from './styles/documents';
import ListContentSkeletonItem from './ListContentSkeletonItem';

const ListContent = ({ data, isLoading }) => {
  if (isLoading) {
    return (
      <ListContainer>
        {Array.from(Array(10).keys()).map((i) => <ListContentSkeletonItem key={i} />)}
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      {data.map(({
        doc, results,
      }) => (
        <ListItem key={uuid()} doc={doc} results={results} />
      ))}
    </ListContainer>
  );
};

ListContent.defaultProps = {
  isLoading: true,
};

ListContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    doc: PropTypes.shape({
      object_uuid: PropTypes.string,
    }),
  })).isRequired,
  isLoading: PropTypes.bool,
};

export default ListContent;
