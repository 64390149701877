// eslint-disable-next-line import/prefer-default-export
export const EVENTS = {
  evt_search_total: 'evt_search_total',
  evt_search_all: 'evt_search_all',
  evt_search_data: 'evt_search_data',
  evt_search_ocr: 'evt_search_ocr',
  evt_search_date: 'evt_search_date',
  evt_search_ocr_visor: 'evt_search_ocr_visor',
  evt_navigate_calendar: 'evt_navigate_calendar',
  evt_click_calendar: 'evt_click_calendar',
  evt_view_description: 'evt_view_description',
  evt_view_images: 'evt_view_images',
  evt_download_pdf: 'evt_download_pdf',
  evt_download_jpg: 'evt_download_jpg',
  evt_download_tif: 'evt_download_tif',
  evt_navigate_thmb: 'evt_navigate_thmb',
  evt_navigate_cutout: 'evt_navigate_cutout',
  evt_login: 'evt_login',
  evt_logout: 'evt_logout',
};
