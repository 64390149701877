import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LoginContext } from '../contexts/LoginContext';
import { verifyCASTicket } from '../api/auth';
import ROUTES from '../constants/routes';

const CasGuard = ({ children }) => {
  const { isLogged } = useContext(LoginContext);

  const searchParams = new URLSearchParams(window.location.search);
  const ticket = searchParams.get('ticket');

  return (
    <>
      {(isLogged || (process.env.REACT_APP_CAS_SERVER_URL || '') === '')
        ? (
          <>{ children }</>
        )
        : (
          <>
            {typeof ticket === 'string'
              ? (
                verifyCASTicket(ticket)
                  .then(() => {
                    window.location.replace(ROUTES.search);
                  })
                  .catch(() => {
                    window.location.replace(process.env.REACT_APP_CAS_LOGIN_URL);
                  })
              )
              : (
                window.location.replace(process.env.REACT_APP_CAS_LOGIN_URL)
              )}
          </>
        )}
    </>
  );
};

CasGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CasGuard;
