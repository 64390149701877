import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CarouselPageCompound } from '..';
import { PageContextProvider } from '../../contexts/PageContext';
import { CoverContainer } from '../../containers';
import { GridItemTitle } from './styles/documents';
import { Result } from '../../shared';

const GridItem = ({ doc, results }) => (
  <Result.Grid>
    {/* page snippet or cover */}
    {results.length
      ? (
        <PageContextProvider pageID={results[0]?.page || ''}>
          <CarouselPageCompound
            id={doc.object_uuid}
            path={doc.path}
            results={results}
          />
        </PageContextProvider>
      )
      : (
        <Link to={`/viewer/${doc.object_name}`}>
          <CoverContainer id={doc.object_uuid} />
        </Link>
      )}

    <GridItemTitle to={`/document/${doc.object_name}`}>
      {doc.object_title}
    </GridItemTitle>
  </Result.Grid>
);

GridItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  doc: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.any.isRequired,
};

export default GridItem;
