import React from 'react';
import LeftSearchForm from './LeftSearchForm';
import { CalendarContainer } from '../../containers';
import { AdvancedSearchContent, AdvancedSearchWrapper } from './styles/advancedSearch';

const AdvancedSearchCompund = () => (
  <main>
    <AdvancedSearchWrapper>
      <AdvancedSearchContent>
        <LeftSearchForm />
        <CalendarContainer />
      </AdvancedSearchContent>
    </AdvancedSearchWrapper>
  </main>
);

export default AdvancedSearchCompund;
