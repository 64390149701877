import styled from 'styled-components/macro';

export const StatsWrapper = styled.div`
    display: flex;
`;

export const StatsText = styled.h3`
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.colors.text};
    margin: 1rem 0 2rem;
`;
