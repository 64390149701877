import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loader from 'react-loader-spinner';
import App from './App';
import 'normalize.css';
import './scss/index.scss';
import './i18n';
import { theme } from './jsStyles/colorPalette';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader type="ThreeDots" height={30} color={theme.colors.primaryLight} className="loader" />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
