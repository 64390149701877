import styled from 'styled-components/macro';

export const Container = styled.div``;

export const Item = styled.div`
    position: absolute;
    background-color: yellow;
    opacity: 50%;
    box-shadow: 1px 1px 1px black;
    left: ${(props) => `${props.x}%`};
    top: ${(props) => `${props.y}%`};
    width: ${(props) => `${props.w}%`};
    height: ${(props) => `${props.h}%`};

    &:hover{
        cursor: pointer;
    }
`;
