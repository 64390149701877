import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import Loader from 'react-loader-spinner';
import { fetchRights } from '../../api/db';
import { theme } from '../../jsStyles/colorPalette';
import RightContent from '../../components/document/RightContent';

const RightsContainer = ({ rightId }) => {
  const {
    data: rights, isLoading: isLoadingRights, isError: isErrorRights,
  } = useQuery('rights', () => fetchRights(rightId),
    {
      refetchOnWindowFocus: false,
    });

  if (isLoadingRights) {
    return <Loader type="ThreeDots" color={theme.colors.primaryLight} height={30} className="loader" />;
  }

  if (isErrorRights) {
    return (
      <span>
        Error
      </span>
    );
  }

  return (
    <RightContent right={rights[0]} />
  );
};

RightsContainer.propTypes = {
  rightId: PropTypes.string.isRequired,
};

export default RightsContainer;
