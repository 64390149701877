import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchParamsContext } from '../../contexts/SearchParams';
import {
  SearchbarWrapper, Input,
} from './styles/hero';

const Searchbar = () => {
  const { t } = useTranslation();
  const { dispatchSearchParams, REDUCER_CASES } = useContext(SearchParamsContext);

  const handleChange = (e) => {
    const { value } = e.target;
    dispatchSearchParams({
      type: REDUCER_CASES.addSingleField,
      payload: {
        category: 'all_fields',
        value,
      },
    });
  };

  return (
    <SearchbarWrapper>
      <Input placeholder={t('quickSearch')} onChange={(e) => handleChange(e)} />
    </SearchbarWrapper>
  );
};

export default Searchbar;
