export const MULTI_PARAMS = [
  'facet_object_institution_desc',
  'facet_fond_name',
  'facet_object_creator',
  'facet_object_type',
  'facet_object_language',
];

export const FACET_ORDER = [
  'facet_object_institution_desc',
  'facet_fond_name',
  'facet_object_creator',
  'facet_object_year',
  'facet_object_type',
  'facet_object_language',
];
