import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  BsLink45Deg,
} from 'react-icons/bs';
import { AiFillFacebook, AiFillTwitterCircle } from 'react-icons/ai';
import { FaWhatsappSquare } from 'react-icons/fa';
import { Tooltip } from '@material-ui/core';
import { Share } from './styles/document';
import { theme } from '../../jsStyles/colorPalette';

const DocumentShare = () => {
  const [open, setOpen] = React.useState(false);

  const currentUrlLocation = window.location.href;

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(currentUrlLocation);
    setOpen(true);
    setTimeout(() => setOpen(false), 3000);
  };

  return (
    <Share>
      <FacebookShareButton url={currentUrlLocation}>
        <AiFillFacebook size={24} color={theme.colors.facebook} />
      </FacebookShareButton>
      <TwitterShareButton url={currentUrlLocation}>
        <AiFillTwitterCircle size={24} color={theme.colors.twitter} />
      </TwitterShareButton>
      <WhatsappShareButton url={currentUrlLocation}>
        <FaWhatsappSquare size={24} color={theme.colors.whatsapp} />
      </WhatsappShareButton>
      <Tooltip
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copiado"
        arrow
        placement="top"
      >
        <div>
          <BsLink45Deg
            size={24}
            onClick={handleTooltipOpen}
            cursor="pointer"
            color={theme.colors.text}
          />
        </div>
      </Tooltip>
    </Share>
  );
};

export default DocumentShare;
