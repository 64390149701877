import React, { useContext } from 'react';
import { isEmpty } from 'ramda';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { deleteEmptyValues } from '../../utils/sanitize';
import MultiTerms from './MultiTerms';
import SingleTerms from './SingleTerms';
import { SearchTermsWrapper } from './styles/searchTerms';

const SearchTerms = () => {
  const { searchParams } = useContext(SearchParamsContext);

  const cleanValues = deleteEmptyValues(searchParams);

  return (
    <>
      {isEmpty(cleanValues)
        ? null
        : (
          <SearchTermsWrapper>
            {Object.entries(cleanValues).map((s) => {
              const [category, values] = s;
              return (
                <div key={category}>
                  {Array.isArray(values)
                    ? <MultiTerms category={category} values={values} />
                    : <SingleTerms category={category} values={values} />}
                </div>
              );
            })}
          </SearchTermsWrapper>
        )}
    </>
  );
};

export default SearchTerms;
