import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { SearchParamsContext } from '../../contexts/SearchParams';

const Pagination = ({ hits }) => {
  const { pagination, setPagination } = useContext(SearchParamsContext);
  const max = process.env.REACT_APP_MAX_RESULTS;

  return (
    <ReactPaginate
      containerClassName="react-paginate"
      forcePage={pagination}
      pageCount={hits / max}
      pageRangeDisplayed={3}
      marginPagesDisplayed={3}
      onPageChange={(e) => setPagination(e.selected)}
      previousLabel="<"
      nextLabel=">"
    />
  );
};

Pagination.propTypes = {
  hits: PropTypes.number.isRequired,
};

export default Pagination;
