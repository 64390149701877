import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { Modal } from '../../shared';
import { logout, removeUser } from '../../api/auth';
import LoginForm from './LoginForm';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContainer, Button } from './styles/login';

const Login = () => {
  const { t } = useTranslation();
  const { isLogged, setIsLogged } = useContext(LoginContext);
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const enterLogoutState = () => {
    removeUser();
    setIsLogged(false);
    history.push(ROUTES.home);
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        enterLogoutState();
      })
      .catch(() => {
        enterLogoutState();
      });
  };

  return (
    <LoginContainer>
      {isLogged
        ? <Button onClick={handleLogout} type="button">{t('login.logout')}</Button>
        : (
          <>
            <Button onClick={handleModal} type="button">{t('login.login')}</Button>
            <Modal isOpen={isOpen} handleModal={handleModal}>
              <LoginForm handleModal={handleModal} />
            </Modal>
          </>
        )}
    </LoginContainer>
  );
};

export default Login;
