import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ThemeProvider } from 'styled-components';
import { ConfContextProvider } from './contexts/ConfContext';
import { FormContextProvider } from './contexts/FormContext';
import { LatestSearchContextProvider } from './contexts/LatestSearchContext';
import { LoginContextProvider } from './contexts/LoginContext';
import { SearchParamsProvider } from './contexts/SearchParams';
import Router from './router/Router';
import { theme } from './jsStyles/colorPalette';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfContextProvider>
        <LoginContextProvider>
          <FormContextProvider>
            <LatestSearchContextProvider>
              <SearchParamsProvider>
                <ThemeProvider theme={theme}>
                  <Router />
                </ThemeProvider>
              </SearchParamsProvider>
            </LatestSearchContextProvider>
          </FormContextProvider>
        </LoginContextProvider>
      </ConfContextProvider>
    </QueryClientProvider>
  );
}

export default App;
