import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LatestSearchContext } from '../../contexts/LatestSearchContext';
import SearchItem from './SearchItem';
import { SearchListWrapper, Title } from './styles/drawer';

const SearchList = () => {
  const { latestSearchs } = useContext(LatestSearchContext);
  const { t } = useTranslation();

  return (
    <SearchListWrapper>
      <Title>{t('latestSearches.title')}</Title>
      {latestSearchs.map((search) => (
        <SearchItem key={search.id} searchParams={search} />
      ))}
    </SearchListWrapper>
  );
};

export default SearchList;
