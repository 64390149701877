import styled from 'styled-components/macro';
import Select from 'react-select';

// eslint-disable-next-line import/prefer-default-export
export const LanguageSelect = styled(Select)`
  .language__control {
    background-color: unset;
    border-color: transparent;
    overflow: visible;
    height: 100%;

    &:hover {
      background-color: rgba(0,0,0,.2);
      border-color: transparent;
    }
  }

  .language__value-container {
    margin-top: 1rem;
    overflow: visible;
    position: relative;
  }

  .language__single-value {
    color: ${({ theme }) => theme.colors.light};
    font-weight: bold;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    max-width: unset;
    overflow: visible;
    position: relative;
    width: max-content;
  }

  img {
    border: 1px solid gray;
    position: relative;
    width: 100%;
  }

  .language__menu {
    background-color: ${({ theme }) => theme.colors.primary};
    width: max-content;
  }

  .language__menu-list {
    width: max-content;
  }

  .language__option {
    color: ${({ theme }) => theme.colors.light};

    &:hover {
      color: ${({ theme }) => theme.colors.dark};
      background-color: ${({ theme }) => theme.colors.primaryLight};
    }
  }
`;
