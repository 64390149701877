import styled from 'styled-components/macro';

export const SortWrapper = styled.div`
    border-left: 1px solid ${({ theme }) => theme.colors.gray};
    border-right: 1px solid ${({ theme }) => theme.colors.gray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    display: flex;
    align-items: center;
    padding: 0.1rem;
`;

export const SortListWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const SortBy = styled.span`
    margin-left: 2rem;
    font-family: ${({ theme }) => theme.fonts.bold};
    color: ${({ theme }) => theme.colors.text};
`;

export const SortFieldWrapper = styled.div`

`;

export const Button = styled.button`
    border: none;
    padding: 0.3rem 1rem;
    background: none;
    text-transform: capitalize;
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.colors.text};
    position: relative;
    cursor: pointer;

    &:hover{
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const Arrow = styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0.3rem;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    ${({ order, theme }) => (order === 'asc' ? `border-bottom: 8px solid ${theme.colors.primary};` : `border-top: 8px solid ${theme.colors.primary};`)}
`;
