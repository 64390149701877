import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { bigScreen } from '../../../jsStyles/viewport';

export const HeaderWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
`;

export const Container = styled.header`
    color: ${({ theme }) => theme.colors.lighter};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    top: 0;
    width: 75%;
    max-width: ${bigScreen};
    margin: 0 auto;
`;

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    margin: 0 auto;
    width: 100%;
    height: 84px;
    justify-content: center;
`;

export const FooterLink = styled(Link)`
    color: ${({ theme }) => theme.colors.lighter};
    font-size: 14px;
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: underline;
    }
`;

export const FooterAnchor = styled.a`
    color: ${({ theme }) => theme.colors.lighter};
    font-size: 14px;
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: underline;
    }
`;
