import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  RelatedWrapper, Title,
} from './styles/document';
import { GridContainer } from '../documents/styles/documents';
import GridItem from '../documents/GridItem';

const RelatedDocuments = ({ related }) => {
  const { t } = useTranslation();

  return (
    <RelatedWrapper>
      <Title>{t('document.related')}</Title>
      <GridContainer border={false}>
        {related.map(({ doc, results }) => (
          <GridItem key={doc.object_uuid} doc={doc} results={results} />
        ))}
      </GridContainer>
    </RelatedWrapper>
  );
};
RelatedDocuments.propTypes = {
  related: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
};

export default RelatedDocuments;
