/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import DocumentInfo from './DocumentInfo';
import DocumentImage from './DocumentImage';
import {
  DocumentWrapper, DocumentContent, DocumentTop, Document, Title,
} from './styles/document';
import DocumentShare from './DocumentShare';
import RelatedDocumentsContainer from '../../containers/relatedDocuments';

const DocumentCompound = ({ document }) => {
  const {
    object_uuid: objectId,
    object_title: title,
    object_type: objectType,
    object_language: objectLang,
  } = document;
  return (
    <DocumentWrapper>
      <DocumentContent>
        <DocumentTop>
          <Title>{title}</Title>
          <DocumentShare />
          <Document>
            <DocumentImage id={objectId} />
            <DocumentInfo info={document} />
          </Document>
        </DocumentTop>
        <RelatedDocumentsContainer params={{
          object_uuid: objectId,
          object_type: objectType,
          object_language: objectLang,
        }}
        />
      </DocumentContent>
    </DocumentWrapper>
  );
};

DocumentCompound.propTypes = {
  document: PropTypes.objectOf.isRequired,
};

export default DocumentCompound;
