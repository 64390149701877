import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { bigScreen } from '../../../jsStyles/viewport';

export const DocumentsWrapper = styled.div`
  height: 100%;
  margin-bottom: 3rem;
`;

export const DocumentsContent = styled.div`
    max-width: ${bigScreen};
    margin: 0 auto;
`;

export const DocumentsContentTop = styled.div`
    max-width: ${bigScreen};
    padding: 0 2rem;
`;

export const DocumentsContentBottom = styled.div`
    display: flex;
    position: relative;
    padding: 0 2rem;
    gap: 1rem;
`;

export const DocumentsResults = styled.div`
    width: 100%;
`;

export const Title = styled.h3`
    font-family: ${({ theme }) => theme.fonts.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
`;

export const HeaderWrapper = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: ${({ theme }) => theme.colors.grayLight};
    padding: .1rem 1rem;
    border: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const Counter = styled.div`
    font-family: ${({ theme }) => theme.fonts.light};
    padding: .3rem .3rem;
    display: block;
    text-align: start;
`;

export const CounterNumber = styled.span`
    font-family: ${({ theme }) => theme.fonts.bold};
`;

export const OptionsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.normal};
`;

export const Options = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const Option = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
    margin: 0;
    cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
    fill: ${({ selected, theme }) => (selected ? theme.colors.text : theme.colors.primary)};
    color: ${({ selected, theme }) => (selected ? theme.colors.text : theme.colors.primary)};
    text-decoration: ${({ selected }) => (selected ? 'underline' : null)};

    & > svg {
        width: 1rem;
        height: 1rem;
    }

    &:hover{
        fill: ${({ selected, theme }) => (selected ? theme.colors.text : theme.colors.primaryLight)};
        color: ${({ selected, theme }) => (selected ? theme.colors.text : theme.colors.primaryLight)};
    }
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 12rem);
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    border-left: ${({ border, theme }) => (border ? `1px solid ${theme.colors.gray}` : null)} ;
    border-right: ${({ border, theme }) => (border ? `1px solid ${theme.colors.gray}` : null)} ;
`;

export const ListContainer = styled.div`
    border-left: 1px solid ${({ theme }) => theme.colors.gray};
    border-right: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const ListItemTitle = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-family: ${({ theme }) => theme.fonts.normal};

    &:hover{
        color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

export const GridItemTitle = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.normal};
    max-width: 12rem;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &:hover{
        color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

export const ImageItem = styled.div`
    display: flex;
    flex-direction: column;
    place-self: self-start;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const InfoRow = styled.div`
    display: flex;
`;

export const InfoText = styled.p`
    font-family: ${({ theme }) => theme.fonts.light};
    margin: 0.2rem 1rem 0.2rem 0;
    color: ${({ theme }) => theme.colors.text};
    overflow-wrap: anywhere;
`;
