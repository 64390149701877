import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { fetchNameByDate, postAudit } from '../../api/db';
import { Day } from './styles/advancedSearch';
import { EVENTS } from '../../constants/events';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { FIELDS } from '../../constants/lastSearchs';

const Single = ({ day }) => {
  const history = useHistory();
  const { dispatchSearchParams, REDUCER_CASES } = useContext(SearchParamsContext);

  const {
    data: name, isLoading, error, isError,
  } = useQuery(`name-${day}`, () => fetchNameByDate(day), { refetchOnWindowFocus: false });

  if (isLoading) {
    return (<div>Loading</div>);
  }

  if (isError) {
    return (<div>{error}</div>);
  }

  const handleClick = (e) => {
    e.stopPropagation();
    dispatchSearchParams({
      type: REDUCER_CASES.addAll,
      payload: {
        value: {
          ...FIELDS,
          object_data: day,
        },
      },
    });
    postAudit(EVENTS.evt_click_calendar, day);
    history.push(`/document/${name}`);
  };

  return (
    <Day onClick={(e) => handleClick(e)} />
  );
};

Single.propTypes = {
  day: PropTypes.string.isRequired,
};

export default Single;
