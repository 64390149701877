/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchParamsContext } from '../../contexts/SearchParams';
import SortField from './SortField';
import { SortListWrapper, SortBy } from './styles/sort';

const SortList = () => {
  const { t } = useTranslation();

  const { sort } = useContext(SearchParamsContext);

  return (
    <>
      <SortBy>
        {t('sortby')}
        :
      </SortBy>
      <SortListWrapper>
        {Object.entries(sort)
          .map(([k, v]) => (
            <SortField key={k} field={k} type={v} />
          ))}
      </SortListWrapper>
    </>
  );
};

export default SortList;
