import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Arrow, Button } from './styles/sort';
import { SearchParamsContext } from '../../contexts/SearchParams';

const CIRCULAR_STATE = [null, 'asc', 'desc'];

const SortField = ({ field, type }) => {
  const { t } = useTranslation();
  const { handleSortChange } = useContext(SearchParamsContext);

  const handleClick = () => {
    const index = CIRCULAR_STATE.findIndex((i) => i === type);
    let newType = 0;
    if (index >= CIRCULAR_STATE.length - 1) {
      newType = null;
    } else {
      newType = CIRCULAR_STATE[index + 1];
    }
    handleSortChange(field, newType);
  };

  return (
    <Button
      onClick={() => handleClick()}
    >
      {t(field)}
      {type && <Arrow order={type} />}
    </Button>
  );
};

SortField.defaultProps = {
  type: '',
};

SortField.propTypes = {
  field: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default SortField;
