import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from './styles/button';

const Button = ({
  children,
  type,
  onClick,
  color,
  bgColor,
}) => (
  <StyledButton type={type} onClick={onClick} color={color} bgColor={bgColor}>
    {children}
  </StyledButton>
);

Button.defaultProps = {
  type: 'button',
  color: 'white',
  bgColor: 'black',
  onClick: null,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  bgColor: PropTypes.string,
};

export default Button;
