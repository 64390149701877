import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiFillHome } from 'react-icons/ai';
import { DocumentContainer } from '../containers';
import { Breadcrumb } from '../shared';
import { postAudit } from '../api/db';
import { EVENTS } from '../constants/events';
import ROUTES from '../constants/routes';

const Document = () => {
  const { name } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    postAudit(EVENTS.evt_view_description, name);
  }, []);

  document.title = [
    process.env.REACT_APP_NAME,
    t('breadcrumb.results'),
    name,
  ].join(' · ');

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Start>
          <AiFillHome size={20} />
        </Breadcrumb.Start>
        <Breadcrumb.Link to={ROUTES.documents} text={t('breadcrumb.results')} />
        <Breadcrumb.Text text={name} />
      </Breadcrumb>
      <main>
        <DocumentContainer />
      </main>
    </>
  );
};
export default Document;
