import styled from 'styled-components/macro';

export const SearchTermsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    font-family: ${({ theme }) => theme.fonts.normal};
    margin: 1rem 0;
`;

export const Tag = styled.div`
    display: flex;
    align-items: center;
    gap: .3rem;
    padding: .5rem;
    border: 1px solid ${({ theme }) => theme.colors.primaryLight};
    color: ${({ theme }) => theme.colors.primaryLight};

    &:hover {
        border-color: ${({ theme }) => theme.colors.warn};
        color: ${({ theme }) => theme.colors.warn};
        cursor: pointer;
    }
`;

export const Label = styled.span`
`;

export const Value = styled.span`
    font-family: ${({ theme }) => theme.fonts.light};
`;
