import React, { useContext, useState, useLayoutEffect } from 'react';
import { useQuery } from 'react-query';
import { fetchDocuments } from '../../api/solr';
import DocumentsContent from '../../components/documents/DocumentsContent';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { ConfContext } from '../../contexts/ConfContext';

const DocumentsContainer = () => {
  const { searchParams, sort, pagination } = useContext(SearchParamsContext);

  const { env } = useContext(ConfContext);

  const { max_results_thumbs: thumbs, search_max_results: max } = env;

  const {
    data, isLoading, isError,
  } = useQuery(
    ['documents', searchParams, sort, pagination],
    () => fetchDocuments(searchParams,
      sort,
      pagination,
      thumbs.var_value,
      max.var_value),
    { refetchOnWindowFocus: false },
  );

  const [preData, setPreData] = useState({
    documents: [],
    facets: {
      facet_fields: {},
      facet_ranges: {},
    },
    hits: 0,
  });

  useLayoutEffect(() => {
    if (data) setPreData(data);
  }, [data]);

  if (isError) {
    return (
      <span>
        Error
      </span>
    );
  }

  return (
    <DocumentsContent
      data={preData}
      isLoading={isLoading}
    />
  );
};

export default DocumentsContainer;
