import styled from 'styled-components/macro';
import close from '../../../assets/images/icons/close.svg';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    z-index: 11;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CloseButton = styled.div`
    width: 2rem;
    height: 2rem;
    background-image: url(${close});
    background-repeat: no-repeat;
    background-size: auto;
    position: absolute;
    top: 0;
    right: 0;
    margin: 2rem;
    cursor: pointer;
`;
