/* eslint-disable max-len */
export const SEARCH_PARAMS_KEY = 'searchParams';

export const getSearchParams = () => JSON.parse(window.localStorage.getItem(SEARCH_PARAMS_KEY)) || {};
export const setSearchParams = (searchParams) => window.localStorage.setItem(SEARCH_PARAMS_KEY, searchParams);
export const removeSearchParams = () => window.localStorage.removeItem(SEARCH_PARAMS_KEY);

export const SORT_PARAMS_KEY = 'SortParams';

export const getSortParams = () => JSON.parse(window.localStorage.getItem(SORT_PARAMS_KEY)) || {
  fond_name: null,
  object_creator: null,
  object_reference: null,
  object_fromdate: null,
  object_institution_desc: null,
};
export const setSortParams = (sortParams) => window.localStorage.setItem(SORT_PARAMS_KEY, sortParams);
export const removeSortParams = () => window.localStorage.removeItem(SORT_PARAMS_KEY);
