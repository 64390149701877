import { get, post } from './restClient';

/* eslint-disable camelcase */
export const fetchDocuments = async (searchParams, sort, page, thumbs, maxResults) => {
  const { data } = await post(`${process.env.REACT_APP_CONTENT_SEARCH_SERVER}/documents?page=${page}&thumbs=${thumbs}&max=${maxResults}`, JSON.stringify({ searchParams, sort }));
  if (data) {
    return data;
  }
  throw new Error('fetchDocuments error');
};

export const fetchRelatedDocuments = async (params) => {
  const { data } = await post(`${process.env.REACT_APP_CONTENT_SEARCH_SERVER}/related`, JSON.stringify(params));
  if (data) {
    return data;
  }
  throw new Error('fetchRelatedDocuments error');
};

export const fetchSnippets = async (id, page, searchParams, outcut) => {
  const { ocr_text } = searchParams;
  const { data } = await get(`${process.env.REACT_APP_CONTENT_SEARCH_SERVER}/snippets/${id}/${page}?q=${ocr_text}&outcut=${outcut}`);
  if (data) {
    return data;
  }
  throw new Error('fetchSnippets error');
};

export const fetchStats = async () => {
  const { data } = await get(`${process.env.REACT_APP_CONTENT_SEARCH_SERVER}/stats`);
  if (data) {
    return [
      { stat_element: 'object_count', stat_value: data.object_count },
      { stat_element: 'object_fromdate_min', stat_value: data.fromdate_minimum },
      { stat_element: 'object_todate_max', stat_value: data.todate_maximum },
    ];
  }
  throw new Error('fetchStats error');
};

export const fetchSelectorLists = async (searchParams = {}) => {
  const { data } = await post(`${process.env.REACT_APP_CONTENT_SEARCH_SERVER}/selectorLists`, JSON.stringify(searchParams));
  if (data) {
    return {
      fond: data.facet_fond_name.sort(),
      creator: data.facet_object_creator.sort(),
      institution: data.facet_object_institution_desc.sort(),
      object_type: data.facet_object_type.sort(),
      language: data.facet_object_language.sort(),
    };
  }
  throw new Error('fetchStats error');
};
