import styled from 'styled-components/macro';
import Select from 'react-select';
import { mediumScreen } from '../../../jsStyles/viewport';
import InputField from '../InputField';

export const AdvancedSearchWrapper = styled.div`
    font-family: ${({ theme }) => theme.fonts.normal};
`;

export const AdvancedSearchContent = styled.div`
    max-width: ${mediumScreen};
    margin: 2rem auto;
    display: flex;
`;

export const FormWrapper = styled.div`
    margin: 0 auto;
`;

export const LeftSearchFormContent = styled.div`
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
    padding: 2rem;
`;

export const CalendarSearchFormContent = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
    padding: 2rem;
`;

export const Title = styled.h3`
    font-family: ${({ theme }) => theme.fonts.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
`;

export const FormikWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 25rem;
`;

export const Input = styled(InputField)`
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
    border-radius: 4px;
    outline: none;
    margin-top: 0.5rem;
    padding: 0.5rem 0.5rem;
    color: ${({ theme }) => theme.colors.text};

    &:focus{
        border: 1px solid #2884FF;
        outline: 1px solid #2884FF;
    }
`;

export const SelectWrapper = styled(Select)`
    position: relative;
    margin-top: 0.5rem;
`;

export const InputDateWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > * {
      width: 45%;
    }
`;

export const FormButtons = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
`;

export const Button = styled.button`
      padding: 0.6rem 2.4rem;
`;

export const Day = styled.span`
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.light};
  opacity: 40%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 2%;
  right: 2%;
  width: 100%;
  height: 100%;
  padding-right: 0.3rem;

  &:hover{
      cursor: pointer;
  }
`;
