import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Skeleton } from '@material-ui/lab';
import { fetchPageCover } from '../../api/db';
import { SnippetCover } from '../../components';

const SnippetCoverContainer = ({ fileCode, coordinates }) => {
  const {
    data, error, isLoading, isError,
  } = useQuery(`cover-${fileCode}`, () => fetchPageCover(fileCode), { refetchOnWindowFocus: false });

  if (isLoading) {
    return (<Skeleton width="12rem" height="16rem" />);
  }

  if (isError) {
    return (
      <span>
        Error:
        {error}
      </span>
    );
  }

  return (
    <SnippetCover cover={data} coordinates={coordinates} />
  );
};

SnippetCoverContainer.propTypes = {
  fileCode: PropTypes.string.isRequired,
  coordinates: PropTypes.shape({
    rx: PropTypes.string,
    ry: PropTypes.string,
    rw: PropTypes.string,
    rh: PropTypes.string,
  }).isRequired,
};

export default SnippetCoverContainer;
