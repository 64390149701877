import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCloseCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { SearchParamsContext } from '../../contexts/SearchParams';
import {
  Tag, Label, Value,
} from './styles/searchTerms';

const SingleTerms = ({ category, values }) => {
  const { dispatchSearchParams, REDUCER_CASES } = useContext(SearchParamsContext);
  const { t } = useTranslation();

  return (
    <Tag onClick={() => dispatchSearchParams({
      type: REDUCER_CASES.removeSingleField,
      payload: {
        category,
      },
    })}
    >
      <Label>
        {t(category)}
        :
      </Label>
      <Value>{values}</Value>
      <AiFillCloseCircle size={16} />
    </Tag>
  );
};

SingleTerms.propTypes = {
  category: PropTypes.string.isRequired,
  values: PropTypes.string.isRequired,
};

export default SingleTerms;
