import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Grid } from '../../assets/images/icons/grid.svg';
import { ReactComponent as List } from '../../assets/images/icons/list.svg';
import { Options, Option, OptionsWrapper } from './styles/documents';

const ShowOptions = ({ showOption, setShowOption }) => {
  const { t } = useTranslation();

  return (
    <OptionsWrapper>
      <Options>
        <Option selected={showOption === 'grid'} onClick={() => setShowOption('grid')}>
          {t('options.gallery')}
          <Grid />
        </Option>
        |
        <Option selected={showOption === 'list'} onClick={() => setShowOption('list')}>
          {t('options.list')}
          <List />
        </Option>

      </Options>
    </OptionsWrapper>
  );
};

ShowOptions.propTypes = {
  showOption: PropTypes.string.isRequired,
  setShowOption: PropTypes.func.isRequired,
};

export default ShowOptions;
