import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles/modal';

const Modal = ({ children, isOpen }) => {
  if (isOpen) {
    return (
      <Container onClick={(e) => e.stopPropagation()} data-testid="modal">
        {children}
      </Container>
    );
  }
  return null;
};

Modal.defaultProps = {
  isOpen: false,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
};

export default Modal;
