import React, { useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiFillHome } from 'react-icons/ai';
import Loader from 'react-loader-spinner';
import { Viewer as UV } from '../components';
import { Breadcrumb } from '../shared';
import { postAudit, fetchDocument } from '../api/db';
import { theme } from '../jsStyles/colorPalette';
import { EVENTS } from '../constants/events';
import ROUTES from '../constants/routes';
import { LoginContext } from '../contexts/LoginContext';
import { removeUser } from '../api/auth';
import FetchError from '../components/FetchError';

const Viewer = () => {
  const { name } = useParams();
  const { t } = useTranslation();
  const { setIsLogged } = useContext(LoginContext);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    postAudit(EVENTS.evt_view_images, name);
  }, []);

  const {
    isLoading: isLoadingDoc, isError: isErrorDoc, error,
  } = useQuery(['document', name], () => fetchDocument(name), { refetchOnWindowFocus: false });

  if (isLoadingDoc) {
    return <Loader type="ThreeDots" color={theme.colors.primaryLight} height={30} className="loader" />;
  }

  if (isErrorDoc) {
    const status = error.response?.status;
    if (status >= 400 && status < 500) {
      removeUser();
      setIsLogged(false);
      window.location.replace(ROUTES.home);
      return <FetchError message={t('errors.unauthorized')} />;
    }

    return <FetchError message={t('errors.generic')} />;
  }

  document.title = [
    process.env.REACT_APP_NAME,
    t('breadcrumb.results'),
    name,
    t('breadcrumb.viewer'),
  ].join(' · ');

  return (
    <div data-testid="Viewer">
      <Breadcrumb>
        <Breadcrumb.Start>
          <AiFillHome size={20} />
        </Breadcrumb.Start>
        <Breadcrumb.Link
          to={ROUTES.documents}
          text={t('breadcrumb.results')}
        />
        <Breadcrumb.Link
          to={`${ROUTES.document}/${name}`}
          text={name}
        />
        <Breadcrumb.Text text={t('breadcrumb.viewer')} />
      </Breadcrumb>
      <UV manifest={`${process.env.REACT_APP_MANIFEST_SERVER}/${name}/manifest`} highlight={searchParams.get('highlight')} width="100%" height="calc(100vh - 5.5rem)" />
    </div>
  );
};

export default Viewer;
