// eslint-disable-next-line no-unused-vars
const sparsisTheme = {
  colors: {
    title: process.env.REACT_APP_TITLE || 'hsl(210deg, 20%, 25%)',
    text: process.env.REACT_APP_TEXT || 'hsl(210deg, 20%, 30%)',
    primary: process.env.REACT_APP_PRIMARY || 'hsl(210deg, 80%, 50%)',
    primaryLight: process.env.REACT_APP_PRIMARY_LIGHT || 'hsl(210deg, 90%, 60%)',
    primaryDark: process.env.REACT_APP_PRIMARY_DARK || 'hsl(210deg, 70%, 40%)',
    gray: process.env.REACT_APP_GRAY || 'hsl(210deg, 10%, 92%)',
    grayLight: process.env.REACT_APP_GRAY_LIGHT || 'hsl(210deg, 10%, 95%)',
    grayDark: process.env.REACT_APP_GRAY_DARK || 'hsl(210deg, 10%, 80%)',
    light: process.env.REACT_APP_LIGHT || 'hsl(210deg, 10%, 99%)',
    dark: process.env.REACT_APP_DARK || 'hsl(210deg, 10%, 10%)',
    warn: process.env.REACT_APP_WARN || 'hsl(0deg, 70%, 50%)',
    lighter: process.env.REACT_APP_LIGHTER || 'white',
    facebook: 'hsl(220deg, 44%, 41%)',
    twitter: 'hsl(203deg, 89%, 53%)',
    whatsapp: 'hsl(129deg, 62%, 44%)',
  },
  fonts: {
    normal: 'Roboto',
    light: 'RobotoLight',
    bold: 'RobotoBold',
  },
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const theme = sparsisTheme;
