import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Counter, CounterNumber } from './styles/documents';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { ConfContext } from '../../contexts/ConfContext';

const Founds = ({ length }) => {
  const { pagination } = useContext(SearchParamsContext);
  const { env } = useContext(ConfContext);
  const { t } = useTranslation();

  const { search_max_results: max } = env;

  return (
    <Counter>
      {t('founds.showing')}
      {' '}
      {(pagination) * max.var_value + 1}
      -
      {(pagination + 1) * max.var_value >= length ? length : (pagination + 1) * max.var_value}
      {' '}
      {t('founds.of')}
      {' '}
      <CounterNumber>{length}</CounterNumber>
      {' '}
      {t('founds.items')}
    </Counter>

  );
};

Founds.propTypes = {
  length: PropTypes.number.isRequired,
};

export default Founds;
