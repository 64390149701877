import React, {
  createContext, useEffect, useReducer, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  getSearchParams, getSortParams, setSearchParams, setSortParams,
} from '../localstorage/searchParams';
import { setPage } from '../localstorage/pagination';

const REDUCER_CASES = {
  addSingleField: 'addSingleField',
  addMultiField: 'addMultiField',
  removeSingleField: 'removeSingleField',
  removeMultiField: 'removeMultiField',
  addAll: 'addAll',
};

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case REDUCER_CASES.removeSingleField: {
      const copyState = Object.fromEntries(Object.entries(state)
        .filter(([k]) => k !== payload.category));
      copyState[payload.category] = '';
      return { ...copyState };
    }
    case REDUCER_CASES.removeMultiField: {
      const copyState = Object.fromEntries(Object.entries(state)
        .filter(([k]) => k !== payload.category));
      const filter = state[payload.category].filter((v) => v !== payload.value);
      copyState[payload.category] = filter;
      return { ...copyState };
    }
    case REDUCER_CASES.addSingleField: {
      const copyState = Object.fromEntries(Object.entries(state)
        .filter(([k]) => k !== payload.category));
      copyState[payload.category] = payload.value;
      return { ...copyState };
    }
    case REDUCER_CASES.addMultiField: {
      const copyState = Object.fromEntries(Object.entries(state)
        .filter(([k]) => k !== payload.category));
      const filter = state[payload.category];
      copyState[payload.category] = [...filter, payload.value];
      return { ...copyState };
    }
    case REDUCER_CASES.addAll: {
      return payload.value;
    }
    default:
      throw new Error('Type not expected');
  }
}

const SearchParamsContext = createContext({});
const SearchParamsProvider = ({ children }) => {
  const [searchParams, dispatchSearchParams] = useReducer(reducer, getSearchParams());
  const [pagination, setPagination] = useState(0);

  const [sort, setSort] = useState(getSortParams());

  useEffect(() => {
    setPagination(0);
    setPage(0);
    setSearchParams(JSON.stringify(searchParams));
  }, [searchParams]);

  useEffect(() => {
    setPage(pagination);
  }, [pagination]);

  useEffect(() => {
    setSortParams(JSON.stringify(sort));
  }, [sort]);

  const handleSortChange = (field, order) => {
    const newState = { ...sort };
    newState[field] = order;
    setSort(newState);
  };

  return (
    <SearchParamsContext.Provider value={{
      searchParams,
      dispatchSearchParams,
      pagination,
      setPagination,
      REDUCER_CASES,
      sort,
      handleSortChange,
    }}
    >
      {children}
    </SearchParamsContext.Provider>
  );
};

SearchParamsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SearchParamsContext, SearchParamsProvider };
