import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { bigScreen } from '../../../jsStyles/viewport';

export const BreadcrumbWrapper = styled.nav`
    background-color: ${({ theme }) => theme.colors.gray};
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.normal};
    margin: 0 auto;
    height: 2.5rem;
    color: ${({ theme }) => theme.colors.text};
    max-width: ${bigScreen};
`;

export const ButtonLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    margin: .4rem;
    font-family: ${({ theme }) => theme.fonts.normal};
    position: relative;
    display: flex;
    align-items: center;
    padding-right: .5rem;
    margin-right: 1rem;

    &:hover{
        color: ${({ theme }) => theme.colors.primaryLight};
    }

    &::before{
        content: ' ';
        display: block;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 10px solid ${({ theme }) => theme.colors.grayDark};
        position: absolute;
        left: 100%;
    }

    &::after{
        content: ' ';
        display: block;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-left: 8px solid ${({ theme }) => theme.colors.gray};
        position: absolute;
        left: 100%;
    }
`;

export const Text = styled.p`
    margin: .4rem;
`;

export const Start = styled.div`
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: .5rem;
    margin-right: 1rem;
    height: 100%;
    position: relative;

    &::before{
        content: ' ';
        display: block;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 10px solid ${({ theme }) => theme.colors.grayDark};
        position: absolute;
        left: 100%;
    }

    &::after{
        content: ' ';
        display: block;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-left: 8px solid ${({ theme }) => theme.colors.gray};
        position: absolute;
        left: 100%;
    }
`;
