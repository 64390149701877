import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Skeleton } from '@material-ui/lab';
import { fetchPageCover } from '../../api/db';
import { PageCover } from '../../components';

const PageCoverContainer = ({ fileCode }) => {
  const {
    data, error, isLoading, isError,
  } = useQuery(`cover-${fileCode}`, () => fetchPageCover(fileCode), { refetchOnWindowFocus: false });

  if (isLoading) {
    return (<Skeleton width="12rem" height="16rem" />);
  }

  if (isError) {
    return (
      <span>
        Error:
        {error}
      </span>
    );
  }

  return (
    <PageCover cover={data} />
  );
};

PageCoverContainer.propTypes = {
  fileCode: PropTypes.string.isRequired,
};

export default PageCoverContainer;
