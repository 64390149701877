import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { format } from 'date-fns';
import { fetchMonthDates, postAudit } from '../../api/db';
import Multiple from './Multiple';
import Single from './Single';
import { FormWrapper, CalendarSearchFormContent, Title } from './styles/advancedSearch';
import { EVENTS } from '../../constants/events';

const CalendarSearchForm = ({ oldest }) => {
  const { i18n, t } = useTranslation();

  const [helperday, setHelperDay] = useState(new Date(oldest));

  const {
    data: dates, refetch,
  } = useQuery(`dates-${helperday}`, () => fetchMonthDates(
    startOfMonth(new Date(helperday)).toISOString().split('T')[0],
    endOfMonth(new Date(helperday)).toISOString().split('T')[0],
  ), { refetchOnWindowFocus: false });

  const handleClick = (date) => {
    setHelperDay(new Date(date));
    refetch();
  };

  const handleContent = (date) => {
    if (dates) {
      const parsedDay = format(date, 'yyyy-MM-dd');
      const count = dates[parsedDay];
      if (count === 1) {
        return (<Single day={parsedDay} />);
      }
      if (count > 1) {
        return (<Multiple day={parsedDay} />);
      }
      return null;
    }
    return null;
  };

  return (
    <FormWrapper>
      <CalendarSearchFormContent>
        <Title>{t('advancedSearch.byData')}</Title>
        <Calendar
          defaultActiveStartDate={new Date(oldest)}
          showNeighboringMonth={false}
          locale={i18n.language}
          tileContent={({ date }) => handleContent(date)}
          onActiveStartDateChange={
            ({
              activeStartDate, view,
            }) => {
              postAudit(EVENTS.evt_navigate_calendar, `${format(activeStartDate, 'yyyy-MM-dd')} ${view}`);
              handleClick(activeStartDate);
            }
          }
        />
      </CalendarSearchFormContent>
    </FormWrapper>
  );
};

CalendarSearchForm.propTypes = {
  oldest: PropTypes.string.isRequired,
};

export default CalendarSearchForm;
