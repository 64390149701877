import React from 'react';
import {
  HeaderWrapper, Container,
} from './styles/footer';

const Footer = () => (
  <HeaderWrapper>
    <Container />
  </HeaderWrapper>
);

export default Footer;
