import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    flex: 1;
  }
`;

export const Item = styled.div``;
