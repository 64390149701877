import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Day } from './styles/advancedSearch';
import { EVENTS } from '../../constants/events';
import { postAudit } from '../../api/db';
import { SearchParamsContext } from '../../contexts/SearchParams';
import { FIELDS } from '../../constants/lastSearchs';
import ROUTES from '../../constants/routes';

const Multiple = ({ day }) => {
  const history = useHistory();
  const { dispatchSearchParams, REDUCER_CASES } = useContext(SearchParamsContext);

  const handleClick = (e) => {
    e.stopPropagation();
    dispatchSearchParams({
      type: REDUCER_CASES.addAll,
      payload: {
        value: {
          ...FIELDS,
          object_data: day,
        },
      },
    });
    postAudit(EVENTS.evt_click_calendar, day);
    history.push(ROUTES.documents);
  };

  return (
    <Day onClick={(e) => handleClick(e)}>
      +
    </Day>
  );
};

Multiple.propTypes = {
  day: PropTypes.string.isRequired,
};

export default Multiple;
