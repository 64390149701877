/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Grid, List, Item,
} from './styles/result';

const Result = ({ children }) => (
  <Container data-testid="Result">
    {children}
  </Container>
);

Result.propTypes = {
  children: PropTypes.node.isRequired,
};

Result.Item = ({ children }) => (
  <Item>{children}</Item>
);

Result.Item.propTypes = {
  children: PropTypes.node.isRequired,
};

Result.List = ({ children, className }) => (
  <List className={className}>{children}</List>
);

Result.List.defaultProps = {
  className: '',
};

Result.List.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Result.Grid = ({ children, className }) => (
  <Grid className={className}>{children}</Grid>
);

Result.Grid.defaultProps = {
  className: '',
};

Result.Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Result;
