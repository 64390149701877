import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LanguageSelector, Login, LastSearchs } from '..';
import {
  HeaderWrapper, Container, Line, ButtonLink, Links, Logo,
  Button,
} from './styles/navbar';
import { IsOpenContext } from '../../contexts/IsOpenContext';
import ROUTES from '../../constants/routes';

const Navbar = () => {
  const { t } = useTranslation();
  const { setIsOpen } = useContext(IsOpenContext);

  /* eslint-disable global-require, import/no-dynamic-require */
  const logo = (
    <Link to={ROUTES.home}>
      <Logo src={require(`../../assets/images/img/${process.env.REACT_APP_LOGO}`).default} />
    </Link>
  );
  /* eslint-enable global-require, import/no-dynamic-require */

  return (
    <HeaderWrapper>
      <Container>
        <Line>
          {logo}
        </Line>
        <Line>
          <Links>
            <ButtonLink to={ROUTES.home}>{t('home')}</ButtonLink>
            <ButtonLink to={ROUTES.search}>{t('search')}</ButtonLink>
            <Button onClick={() => setIsOpen((prevState) => !prevState)}>{t('latest')}</Button>
          </Links>
          <LanguageSelector className="language" />
          <Login />
        </Line>
      </Container>
      <LastSearchs />
    </HeaderWrapper>
  );
};

export default Navbar;
