import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StatsWrapper, StatsText } from './styles/stats';

const Stats = ({ stats }) => {
  const { t } = useTranslation();
  const count = stats.find((s) => s.stat_element === 'object_count');
  const minDate = stats.find((s) => s.stat_element === 'object_fromdate_min');
  const maxDate = stats.find((s) => s.stat_element === 'object_todate_max');

  return (
    <StatsWrapper>
      <StatsText>
        {count.stat_value}
        {' '}
        {t('stats.digitalfrom')}
        {' '}
        {minDate.stat_value}
        {' '}
        {t('stats.to')}
        {' '}
        {maxDate.stat_value}
      </StatsText>
    </StatsWrapper>
  );
};

Stats.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Stats;
